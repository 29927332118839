import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import FormTwo from "../component/contact/FormTwo";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderLogggedIn from "../common/header/HeaderLoggedIn";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Contact = ({ token, setToken, credits, setCredits }) => {
  return (
    <>
      <SEO title="Contact" />
      <ColorSwitcher />
      <main className="main-wrapper">
        {token ? (
          <HeaderLogggedIn
            email={token.user.email}
            setToken={setToken}
            credits={credits}
            setCredits={setCredits}
          />
        ) : (
          <HeaderTwo />
        )}
        <BreadCrumbOne title="" page="Contact" />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">
                    Please don't hesitate to contact us.
                  </h3>

                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <AnimationOnScroll
                  animateIn="slideInRight"
                  duration={1}
                  animateOnce={true}
                >
                  <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                    <h4 className="title">Tipping</h4>
                    <p>
                      If you find our website helpful and want to support its
                      development and maintenance, please consider making a
                      small donation.Thank you for your generosity and support!
                    </p>
                    <h4 className="phone-number">
                      <a href="https://www.paypal.com/">
                        (Paypal)
                        <br /> admin@impressions.one
                      </a>
                    </h4>
                    <h4 className="phone-number">
                      <a href="https://ko-fi.com/impressionsone">
                        (Kofi) <br /> Buy me a coffee
                      </a>
                    </h4>
                  </div>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="slideInRight"
                  duration={1}
                  animateOnce={true}
                >
                  <div className="contact-info mb--30">
                    <h4 className="title">Email</h4>
                    <p>
                      Our support team will get back to you in 48 hours during
                      standard business hours.
                    </p>
                    <h4 className="phone-number">
                      <a href="mailto:admin@impressions.one">
                        admin@impressions.one
                      </a>
                    </h4>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Contact;
