import React from "react";
import { useState } from "react";

const TabCusGen = ({ setSelect, setGenerate }) => {
  const handleSelect = () => {
    setSelect(true);
    setGenerate(false);
  };
  const handleGenerate = () => {
    setGenerate(true);
    setSelect(false);
  };

  return (
    <>
      <div className="mainTabList-cusgen">
        <div class="containerTabList-cusgen">
          <div class="tabs-cusgen cusgen">
            <input
              type="radio"
              id="radio-1-cusgen"
              name="tabs"
              onClick={handleSelect}
            ></input>
            <label class="tab-cusgen" for="radio-1-cusgen">
              Select
            </label>
            <input
              type="radio"
              id="radio-2-cusgen"
              name="tabs"
              onClick={handleGenerate}
            ></input>
            <label class="tab-cusgen" for="radio-2-cusgen">
              Generate
            </label>

            <span class="glider-cusgen"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabCusGen;
