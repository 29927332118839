import React, { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link, useNavigate } from "react-router-dom";
import { message, Upload } from "antd";
import emailjs from "@emailjs/browser";

const { Dragger } = Upload;

const BannerAnalyze = ({ countimg, setCountimg }) => {
  // const [src,setSrc] = useState(null)
  const [count, setCount] = useState(0);
  const [uid, setUid] = useState("");

  const navigate = useNavigate();

  const props = {
    name: "file",
    method: "POST",
    crossOrigin: "anonymous",
    multiple: false,
    action: "https://www.impressions.one/uploads",
    // action: "http://127.0.0.1:5000/uploads",
    // action: "https://impressionsdotonev2test.onrender.com/uploads",
    onChange(info) {
      const { status } = info.file;

      setCount(0);
      // if (status !== "uploading") {
      //   // console.log(info.file, info.fileList);
      // }
      if (status === "done") {
        setCount(1);
        // console.log(info.file.response);
        // console.log(Array.isArray(info.file.response.data));

        if (info.file.response.data) {
          setUid(info.file.response);
        } else {
          emailjs
            .send(
              "service_e680d7j",
              "template_xtw7ojk",
              {
                to_name: "ADMIN",
                from_name: "SYSTEM",
                message: "429 error/analyze",
              },
              "TfuaKdQQ79zIC3Y4D"
            )
            .then(
              (result) => {
                console.log(result.text);
              },
              (error) => {
                console.log(error.text);
              }
            );
          navigate("/429");
        }

        // setUid(info.file.originFileObj.uid);
        // // console.log(uid);
        // fetch("/uid", {
        //   method: "POST",
        //   // We convert the React state to JSON and send it as the POST body
        //   body: JSON.stringify(info.file.originFileObj.uid),
        // }).then(function (response) {
        //   // console.log(response);
        //   return response.json();
        // });

        // console.log(info.file.originFileObj.uid);

        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
      setCount(0);
      // console.log(count);
    },
  };

  return (
    <div className="banner banner-style-2">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-content">
              <h1 className="title">
                AI Analyze <br /> Thumbnails
              </h1>
              <p className="banner-body">
                Let data guide you towards thumbnail perfection. Our advanced AI
                analysis tool dives deep into the metrics that define thumbnail
                quality. <br />
              </p>

              <div></div>

              <ul className="dragndropUL">
                <li>
                  <Dragger
                    {...props}
                    listType="picture"
                    maxCount={1}
                    className="drag-image"
                    accept=".png,.jpg,.jpeg,"
                  >
                    <img
                      className="icon"
                      src={process.env.PUBLIC_URL + "/images/up-arrow.png"}
                    />

                    <p className="upload-text">Select or drop your thumbnail</p>
                    <p className="ant-upload-hint"></p>
                  </Dragger>
                </li>

                <li>
                  {count === 0 ? null : (
                    <Link
                      to="/results"
                      state={uid}
                      className="axil-btn btn-fill-white btn-large bannerBtn"
                    >
                      Analyze
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-18">
        <li className="shape shape-1">
          <AnimationOnScroll
            animateIn="slideInRight"
            duration={1}
            animateOnce={true}
            delay={100}
          >
            <img
              className="subPageGraphics"
              src={process.env.PUBLIC_URL + "/images/banner/ytl6.png"}
              alt="Shape"
              onLoad={() => setCountimg(countimg + 1)}
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-2"></li>
        <li className="shape shape-3">
          <AnimationOnScroll
            animateIn="zoomIn"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-4">
          <AnimationOnScroll
            animateIn="zoomIn"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-5">
          <AnimationOnScroll
            animateIn="zoomIn"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-6">
          <AnimationOnScroll
            animateIn="zoomIn"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-7">
          <AnimationOnScroll
            animateIn="slideInDown"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-26.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
      </ul>
    </div>
  );
};

export default BannerAnalyze;
