import { useState, useEffect, React } from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const Data = [
  {
    id: 1,
    light_icon: "/images/icon/shield.png",
    dark_icon: "/images/icon/shield.png",
    countNum: 87,
    text: "Google Ads Safe ",
    title: <b>Safe Search</b>,
  },
  {
    id: 2,
    light_icon: "/images/icon/face-detection.png",
    dark_icon: "/images/icon/face-detection.png",
    countNum: 65,
    text: "Engages the Audience",
    title: <b>Faces</b>,
  },
  {
    id: 3,
    light_icon: "/images/icon/eiffel-tower.png",
    dark_icon: "/images/icon/eiffel-tower.png",
    countNum: 69,
    text: "Attracts the Audience",
    title: <b>Landmarks</b>,
  },
  {
    id: 4,
    light_icon: "/images/icon/social-media.png",
    dark_icon: "/images/icon/social-media.png",
    countNum: 64,
    text: "Creates Credibility",
    title: <b>Logos</b>,
  },
];

const CounterUp = ({ colSize, layoutStyle, evenTopMargin, prop }) => {
  return (
    <>
      {Data.map((data) => (
        <div
          className={`${colSize} ${data.id % 2 === 0 ? evenTopMargin : ""}`}
          key={data.id}
        >
          <div className={`counterup-progress ${layoutStyle}`}>
            {layoutStyle === "counterup-style-2" ? (
              <>
                <h6 className="title">{data.title}</h6>
                <div className="icon">
                  <img
                    className="dark-icon"
                    src={process.env.PUBLIC_URL + data.dark_icon}
                    alt="Icon"
                  />
                  <img
                    className="light-icon"
                    src={process.env.PUBLIC_URL + data.light_icon}
                    alt="Icon"
                  />
                </div>
              </>
            ) : (
              <>
                <h6 className="title">{data.title}</h6>
                <div className="icon">
                  <img
                    src={process.env.PUBLIC_URL + data.light_icon}
                    alt="Icon"
                  />
                </div>
              </>
            )}

            <div className="count-number h2">
              <TrackVisibility once>
                {({ isVisible }) => (
                  <span className="number count">
                    {isVisible ? (
                      data.id == 1 ? (
                        <CountUp end={(prop[0][1] / 45) * 100} duration={1} />
                      ) : data.id == 2 ? (
                        prop[1][1] != 30 ? (
                          <CountUp end={(prop[1][1] / 55) * 100} duration={1} />
                        ) : (
                          <CountUp end={0} duration={1} />
                        )
                      ) : data.id == 3 ? (
                        prop[2][1] === 0 ? (
                          <CountUp end={100} duration={1} />
                        ) : (
                          <CountUp end={0} duration={1} />
                        )
                      ) : prop[3][1] === 0 ? (
                        <CountUp end={100} duration={1} />
                      ) : (
                        <CountUp end={0} duration={1} />
                      )
                    ) : null}
                  </span>
                )}
              </TrackVisibility>
              <span className="symbol">%</span>
            </div>
            <h6 className="title">{data.text}</h6>
          </div>
        </div>
      ))}
    </>
  );
};

export default CounterUp;
