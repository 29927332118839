import { useState, useEffect, React } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import ThreeThumbnails from "../../component/project/ThreeThumbnails";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTwo from "../../common/header/HeaderTwo";
import ImagePreviewBox from "../../component/project/ImagePreviewBox";
import { Link } from "react-router-dom";
import Loadinganim from "../../component/Loadinganim";
import { useLocation } from "react-router-dom";
import { supabase } from "../../utils/supabaseClient";
import { message } from "antd";
import HeaderLogggedIn from "../../common/header/HeaderLoggedIn";

const ThumbGenFinal = ({ token, setToken, credits, setCredits }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedImageMain, setSelectedImageMain] = useState();
  const [count, setCount] = useState(0);
  const location = useLocation();
  const uid = location.state;
  async function getForegrounds() {
    await fetch("/finalTG", {
      method: "POST",
      // We convert the React state to JSON and send it as the POST body
      body: JSON.stringify(uid[0].substring(0, uid[0].length - 4)),
    })
      .then((data) => {
        if (data.status == 200) {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err.message);
      });
  }

  useEffect(() => {
    getForegrounds();
  }, []);

  async function getSessionAuth() {
    // e.preventDefault()

    try {
      // setIsLoading(true);

      const { data, error } = await supabase.auth.getSession();

      if (error) throw error;
      if (data.session) {
        // console.log(data.session);
        setToken(data.session);

        // navigate('/auth-test')
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    }
    // finally {
    //   setIsLoading(false);
    // }
  }
  useEffect(() => {
    getSessionAuth();
  }, []);

  const [colorBg, setColorBg] = useState("#B71C1C");
  const [colorShow, setColorShow] = useState(false);
  if (credits != null) {
    if (uid == null) {
      return <Navigate to="/design-thumbnail" />;
    } else {
      if (isLoading || count < 23) {
        return (
          <>
            <SEO title="Loading" />
            <ColorSwitcher />
            <main className="main-wrapper loadingpg">
              {token ? (
                <HeaderLogggedIn
                  email={token.user.email}
                  setToken={setToken}
                  credits={credits}
                  setCredits={setCredits}
                />
              ) : (
                <HeaderTwo />
              )}
              <Loadinganim />
              <div className="hide-slider">
                <ImagePreviewBox
                  token={token}
                  uid={uid[0]}
                  setSelectedImageMain={setSelectedImageMain}
                  setCount={setCount}
                  count={count}
                  credits={credits}
                  setCredits={setCredits}
                  // colorBg={colorBg}
                  // setColorBg={setColorBg}
                />
                <ThreeThumbnails
                  uid={uid}
                  selectedImageMain={selectedImageMain}
                  setSelectedImageMain={setSelectedImageMain}
                  setCount={setCount}
                  count={count}
                />
              </div>
              <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                    alt="Line"
                  />
                </li>
              </ul>
            </main>
          </>
        );
      } else {
        return (
          <>
            <SEO title="Thumbnail Generator" />
            <ColorSwitcher />
            <main className="main-wrapper ">
              {token ? (
                <HeaderLogggedIn
                  email={token.user.email}
                  setToken={setToken}
                  credits={credits}
                  setCredits={setCredits}
                />
              ) : (
                <HeaderTwo />
              )}

              <div className="banner banner-style-3 thumbgenface ">
                <div className="row">
                  <div className="col-md-12">
                    <ImagePreviewBox
                      token={token}
                      uid={uid[0]}
                      setSelectedImageMain={setSelectedImageMain}
                      setCount={setCount}
                      count={count}
                      colorBg={colorBg}
                      setColorBg={setColorBg}
                      colorShow={colorShow}
                      setColorShow={setColorShow}
                      credits={credits}
                      setCredits={setCredits}
                    />
                    {/* <div className="more-project-btn finalUploadbtn">
                                <Link to={process.env.PUBLIC_URL + ""} className="axil-btn btn-fill-white thumbgenbtn1lnk">Add Selected Backgound</Link>
                            </div> */}
                  </div>
                </div>
                <ThreeThumbnails
                  uid={uid}
                  selectedImageMain={selectedImageMain}
                  setCount={setCount}
                  count={count}
                  colorBg={colorBg}
                  colorShow={colorShow}
                  setColorShow={setColorShow}
                />

                <ul className="list-unstyled shape-group-20">
                  <li className="shape shape-1">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-32.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-33.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-14.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-4.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  {/* <li className="shape shape-6">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-30.png"} alt="Bubble" />
                        </li> */}
                  <li className="shape shape-7">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-31.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-8 marque-images" />
                  <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-14.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-4.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  {/* <li className="shape shape-6">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-30.png"} alt="Bubble" />
                        </li> */}
                  <li className="shape shape-7">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-31.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-8 marque-images" />
                  <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-14.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-4.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  {/* <li className="shape shape-6">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-30.png"} alt="Bubble" />
                        </li> */}
                  <li className="shape shape-7">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-31.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-8 marque-images" />
                </ul>
              </div>

              <FooterTwo />
            </main>
          </>
        );
      }
    }
  } else {
    return (
      <>
        <SEO title="Loading" />
        <ColorSwitcher />
        <main className="main-wrapper loadingpg">
          <HeaderTwo />
          <Loadinganim />

          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      </>
    );
  }
};

export default ThumbGenFinal;
