import React from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

import {
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";

const getServiceData = ServiceData;

const Socials = ({ parentClass }) => {
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    // e.preventDefault();

    message.success(`Submitted successfully.`);
    // navigate('/results');
  };

  return (
    <div className="footer-top">
      <div className="footer-social-link">
        <ul className="list-unstyled">
          <li>
            <a href="https://www.youtube.com/channel/UCOky5lJ8N2W9e1H17Lx1r3g">
              <FaYoutube />
            </a>
          </li>

          <li>
            <a href="https://twitter.com/ImpressionsOne">
              <FaTwitter />
            </a>
          </li>

          <li>
            <a href="https://www.linkedin.com/company/impressions-one/">
              <FaLinkedin />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/impressionsone/">
              <FaInstagram />
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@impressionsdotone?lang=en">
              <FaTiktok />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Socials;
