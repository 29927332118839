import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import HeaderTwo from "../common/header/HeaderTwo";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import CounterUpTwo from "../component/counterup/CounterUpTwo";
import CaseStudyProp from "../component/casestudy/CaseStudyProp";
import CounterUpOne from "../component/counterup/CounterUpOne";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Loadinganim from "../component/Loadinganim";
import HeaderLogggedIn from "../common/header/HeaderLoggedIn";
import FooterWithoutSocials from "../common/footer/FooterWithoutSocials";

const Results = ({ token, setToken, credits, setCredits }) => {
  const [analysisData, setAnalysisData] = useState({});
  // const [isLoading, setIsLoading] = useState(false);

  const [isLoadingImages, setIsLoadingImages] = useState(true);

  // const navigate = useNavigate();

  useEffect(() => {
    const imageElements = document.getElementsByTagName("img");
    const totalImages = imageElements.length;
    let loadedImages = 0;

    const handleImageLoad = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        setIsLoadingImages(false);
      }
    };

    // Attach load event listener to each image element
    for (let i = 0; i < totalImages; i++) {
      imageElements[i].addEventListener("load", handleImageLoad);
    }

    // Clean up the event listener
    return () => {
      for (let i = 0; i < totalImages; i++) {
        imageElements[i].removeEventListener("load", handleImageLoad);
      }
    };
  }, []);

  const location = useLocation();
  const uid = location.state;

  // console.log(uid["data"], uid["fileName"]);
  // console.log(uid);
  // useEffect(async () => {
  //   await fetch("/analyze")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       // console.log(data);
  //       {
  //         for (let i = 0; i < data.dataAn.length; i++) {
  //           data.dataAn[i][6] == uid && setAnalysisData(data.dataAn[i]);
  //           fetch("/delete", {
  //             method: "POST",
  //             // We convert the React state to JSON and send it as the POST body
  //             body: JSON.stringify(uid),
  //           }).then(function (response) {
  //             // console.log(response);
  //             return response.json();
  //           });
  //         }
  //       }

  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       // console.log("Error");
  //       // console.log(error);
  //     });
  // }, []);
  // console.log(analysisData[4]);

  if (uid == null) {
    return <Navigate to="/ai-analyze" />;
  } else {
    if (isLoadingImages) {
      return (
        <>
          <SEO title="Loading" />
          <ColorSwitcher />
          <main className="main-wrapper loadingpg">
            {token ? (
              <HeaderLogggedIn
                email={token.user.email}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            ) : (
              <HeaderTwo />
            )}
            <Loadinganim />
            <div className="hide-slider">
              <CounterUpTwo prop={uid} />
              <CounterUpOne prop={uid} />
              <CaseStudyProp prop={uid} />
            </div>
            <ul className="shape-group-8 list-unstyled">
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                  alt="Line"
                />
              </li>
            </ul>
          </main>
        </>
      );
    } else {
      return (
        <>
          <SEO title="Analyze Thumbnail" />
          <ColorSwitcher />
          <main className="main-wrapper ">
            {token ? (
              <HeaderLogggedIn
                email={token.user.email}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            ) : (
              <HeaderTwo />
            )}

            <CounterUpTwo prop={uid} />

            <AnimationOnScroll
              animateIn="slideInRight"
              duration={1}
              animateOnce={true}
            >
              <CounterUpOne prop={uid} />
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="slideInLeft"
              duration={1}
              animateOnce={true}
            >
              <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area ">
                <div className="container ">
                  <CaseStudyProp prop={uid} />
                </div>
              </div>
            </AnimationOnScroll>

            <FooterWithoutSocials parentClass="" />
          </main>
        </>
      );
    }
  }
};

export default Results;
