import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import Dialog from "@mui/material/Dialog";
import { ThemeProvider, createTheme } from "@mui/material/styles";
let theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "Poppins"].join(","),
  },
  palette: {
    primary: {
      main: "#00adb4",
    },
    secondary: {
      main: "#00adb4",
    },
  },
});

const Nav = () => {
  const [open, setOpen] = useState(false);
  const [pageToken, setPageToken] = useState("");
  const navigate = useNavigate();
  const handleClickOpen = (pageToken) => {
    setPageToken(pageToken);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function navigateFunction() {
    setOpen(false);
    navigate("/sign-in", { state: pageToken });
  }

  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu profileiconul">
        <li className="menu-item-has-children itemsnav">
          <a target="_blank" className="servicesddm">
            Services
            <FaAngleDown />{" "}
          </a>
          <ul className="axil-submenu">
            <li>
              <Link to="" onClick={() => handleClickOpen("design-thumbnail")}>
                Design Thumbnail{" "}
                <img
                  className="crownicon"
                  src={process.env.PUBLIC_URL + "/images/crown.png"}
                ></img>
              </Link>
            </li>
            <li>
              <Link to="" onClick={() => handleClickOpen("upscale")}>
                Upscale Image{" "}
                <img
                  className="crownicon"
                  src={process.env.PUBLIC_URL + "/images/crown.png"}
                ></img>
              </Link>
            </li>
            <li>
              <Link to="" onClick={() => handleClickOpen("bg-remove")}>
                Remove Background{" "}
                <img
                  className="crownicon"
                  src={process.env.PUBLIC_URL + "/images/crown.png"}
                ></img>
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/ai-analyze"}>
                Analyze Thumbnail
              </Link>
            </li>
          </ul>
        </li>
        <li className="itemsnav">
          <Link to={"/about-us"}>About Us</Link>
        </li>
        <li className="itemsnav">
          <Link to={"/contact"}>Contact</Link>
        </li>
        <li className="itemsnav">
          <Link to={"/pricing"}>Pricing</Link>
        </li>

        <li className="menu-item-has-children itemsnav">
          <li className="profileicon">
            <a className="change-cursor" target="_blank">
              <img
                className="profileicon-light"
                src={process.env.PUBLIC_URL + "images/profilel.png"}
              />
              <img
                className="profileicon-dark"
                src={process.env.PUBLIC_URL + "images/profiled.png"}
              />
              <img
                className="profileicon-imp"
                src={process.env.PUBLIC_URL + "images/profilei.png"}
              />
            </a>
          </li>
          <ul className="axil-submenu axil-submenu-signin">
            <li>
              <Link
                className="centeralign"
                to={process.env.PUBLIC_URL + "/sign-up"}
              >
                &nbsp;
                <LockOpenRoundedIcon />
                &nbsp;&nbsp;&nbsp;Sign Up&nbsp;
              </Link>
            </li>
            <li>
              <Link
                className="centeralign"
                to={process.env.PUBLIC_URL + "/sign-in"}
              >
                <LoginRoundedIcon />
                &nbsp;&nbsp;&nbsp;&nbsp; Sign In&nbsp;
              </Link>
            </li>
          </ul>
        </li>
      </ul>
      <ThemeProvider theme={theme}>
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              width: "2000px",
            },
          }}
          open={open}
          onClose={handleClose}
        >
          <div class="signup-form contact-form-box shadow-box dialog prompt">
            {/* {loadingDialog ? (

                <ThemeProvider theme={theme}>
                  <Box sx={{ width: '100%', paddingBottom: '15px' }}>
                    <LinearProgress sx={{ height: '17px', borderRadius: '8px' }} variant="buffer" value={progress} valueBuffer={buffer} />
                  </Box>
                </ThemeProvider>

              ) : ( */}

            <div class="container signin-container">
              <div class="header">
                <h1 className="signin-title">Please Sign In!</h1>
                <p>Registration is required to access this feature</p>
              </div>
              <div className="loadermain">
                <div class="loader"></div>
              </div>
              <input
                type="submit"
                value="Sign In"
                class="signup-btn dialogButton"
                onClick={navigateFunction}
              />
              <input
                type="button"
                readonly
                onClick={handleClose}
                value="Cancel"
                class="signup-btn dialogButton"
              />
            </div>
            {/* )} */}
          </div>
        </Dialog>
      </ThemeProvider>
    </nav>
  );
};

export default Nav;
