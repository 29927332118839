import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SEO from "../common/SEO";
import SplashCta from "../component/cta/SplashCta";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SplashData from "../data/splash/SplashData.json";
import { slugify } from "../utils";
import HeaderTwo from "../common/header/HeaderTwo";
import BannerOne from "../component/banner/BannerOne";
import FooterOne from "../common/footer/FooterOne";
import HeaderLogggedIn from "../common/header/HeaderLoggedIn";
import { message } from "antd";
import { supabase } from "../utils/supabaseClient";
import Dialog from "@mui/material/Dialog";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import VideoTwo from "../component/video/VideoTwo";
import Loadinganim from "../component/Loadinganim";
import ComparisonSliderHomeGen from "../component/project/ComparisonSliderHomeGen";
import ComparisonSliderHomeUp from "../component/project/ComparisonSliderHomeUp";
import ComparisonSliderHomeAna from "../component/project/ComparisonSliderHomeAna";
import ComparisonSliderHomeBgr from "../component/project/ComparisonSliderHomeBgr";

let theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "Poppins"].join(","),
  },
  palette: {
    primary: {
      main: "#00adb4",
    },
    secondary: {
      main: "#00adb4",
    },
  },
});

const DemoData = SplashData[0];
const FeatureData = SplashData[1];

const Splash = ({ token, setToken, credits, setCredits }) => {
  const [count, setCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [pageToken, setPageToken] = useState("");
  const navigate = useNavigate();
  const handleClickOpen = (pageToken) => {
    setPageToken(pageToken);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function navigateFunction() {
    navigate("/sign-in", { state: pageToken });
  }

  async function getSessionAuth() {
    // e.preventDefault()

    try {
      // setLoading(true)

      const { data, error } = await supabase.auth.getSession();

      if (error) throw error;
      if (data.session) {
        // console.log(data.session);
        setToken(data.session);
      }
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    }
    // finally {
    //   setLoading(false)
    // }
  }
  useEffect(() => {
    getSessionAuth();
  }, []);

  if (count < 9) {
    return (
      <>
        <SEO title="Loading" />
        <ColorSwitcher />
        <main className="main-wrapper loadingpg">
          {token ? (
            <HeaderLogggedIn
              email={token.user.email}
              setToken={setToken}
              credits={credits}
              setCredits={setCredits}
            />
          ) : (
            <HeaderTwo />
          )}
          <Loadinganim />
          <div className="hide-slider">
            <ComparisonSliderHomeGen count={count} setCount={setCount} />
            <ComparisonSliderHomeUp count={count} setCount={setCount} />
            <ComparisonSliderHomeAna count={count} setCount={setCount} />
            <ComparisonSliderHomeBgr count={count} setCount={setCount} />
            <div className="row">
              <div className="col-md-6" key={DemoData[0].id}>
                <div className="single-demo">
                  <Link
                    to={
                      token
                        ? `${process.env.PUBLIC_URL}/${slugify(
                            DemoData[0].title
                          )}`
                        : ""
                    }
                    onClick={
                      token ? "" : () => handleClickOpen("design-thumbnail")
                    }
                  >
                    <span className="thumb">
                      <img
                        src={`${process.env.PUBLIC_URL}${DemoData[0].height_img}`}
                        alt={DemoData[0].title}
                        onLoad={() => setCount(count + 1)}
                      />
                    </span>
                    <h4 className="title">
                      Design Thumbnail{" "}
                      <img
                        className="crownicon"
                        src={process.env.PUBLIC_URL + "/images/crown.png"}
                      ></img>
                    </h4>
                  </Link>
                  {/* <p>Generate thumnails using our AI model</p> */}
                </div>
              </div>
              <div className="col-md-6" key={DemoData[1].id}>
                <div className="single-demo">
                  <Link
                    to={
                      token
                        ? `${process.env.PUBLIC_URL}/${slugify(
                            DemoData[1].title
                          )}`
                        : ""
                    }
                    onClick={token ? "" : () => handleClickOpen("upscale")}
                  >
                    <span className="thumb">
                      <img
                        src={`${process.env.PUBLIC_URL}${DemoData[1].height_img}`}
                        alt={DemoData[1].title}
                        onLoad={() => setCount(count + 1)}
                      />
                    </span>
                    <h4 className="title">
                      AI Upscale{" "}
                      <img
                        className="crownicon"
                        src={process.env.PUBLIC_URL + "/images/crown.png"}
                      ></img>
                    </h4>
                  </Link>
                </div>
              </div>
              <div className="col-md-6" key={DemoData[2].id}>
                <div className="single-demo">
                  <Link to={process.env.PUBLIC_URL + "bg-remove"}>
                    <span className="thumb">
                      <img
                        src={`${process.env.PUBLIC_URL}${DemoData[2].height_img}`}
                        alt={DemoData[2].title}
                        onLoad={() => setCount(count + 1)}
                      />
                    </span>
                    <h4 className="title">Remove Backgound</h4>
                  </Link>
                </div>
              </div>
              <div className="col-md-6" key={DemoData[3].id}>
                <div className="single-demo">
                  <Link to={process.env.PUBLIC_URL + "ai-analyze"}>
                    <span className="thumb">
                      <img
                        src={`${process.env.PUBLIC_URL}${DemoData[3].height_img}`}
                        alt={DemoData[3].title}
                        onLoad={() => setCount(count + 1)}
                      />
                    </span>
                    <h4 className="title"> Analyze Thumbnail</h4>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      </>
    );
  } else {
    return (
      <>
        <SEO title="Impressions" />
        <ColorSwitcher />
        <main className="main-wrapper">
          {token ? (
            <HeaderLogggedIn
              email={token.user.email}
              setToken={setToken}
              credits={credits}
              setCredits={setCredits}
            />
          ) : (
            <HeaderTwo />
          )}

          <BannerOne />

          <div
            className="section main-demo-area bg-color-light"
            id="splash-demo"
            name="services"
          >
            <div className="container">
              <div className="section-heading heading-left">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-7">
                    <h2 className="title">
                      Grab Your Viewers' Attention Instantly!
                    </h2>
                  </div>
                  <div className="col-xl-4 col-lg-5 offset-xl-2">
                    <p>
                      Thumbnails capture attention, convey information, and
                      drive engagement, ultimately determining whether a viewer
                      clicks to explore further.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6" key={DemoData[0].id}>
                  <div className="single-demo">
                    <Link
                      to={
                        token
                          ? `${process.env.PUBLIC_URL}/${slugify(
                              DemoData[0].title
                            )}`
                          : ""
                      }
                      onClick={
                        token ? "" : () => handleClickOpen("design-thumbnail")
                      }
                    >
                      <span className="thumb">
                        <img
                          src={`${process.env.PUBLIC_URL}${DemoData[0].height_img}`}
                          alt={DemoData[0].title}
                          // call onComplete when image is loaded
                        />
                      </span>
                      <h4 className="title">
                        Design Thumbnail{" "}
                        <img
                          className="crownicon"
                          src={process.env.PUBLIC_URL + "/images/crown.png"}
                        ></img>
                      </h4>
                    </Link>
                    {/* <p>Generate thumnails using our AI model</p> */}
                  </div>
                </div>
                <div className="col-md-6" key={DemoData[1].id}>
                  <div className="single-demo">
                    <Link
                      to={
                        token
                          ? `${process.env.PUBLIC_URL}/${slugify(
                              DemoData[1].title
                            )}`
                          : ""
                      }
                      onClick={token ? "" : () => handleClickOpen("upscale")}
                    >
                      <span className="thumb">
                        <img
                          src={`${process.env.PUBLIC_URL}${DemoData[1].height_img}`}
                          alt={DemoData[1].title}
                        />
                      </span>
                      <h4 className="title">
                        AI Upscale{" "}
                        <img
                          className="crownicon"
                          src={process.env.PUBLIC_URL + "/images/crown.png"}
                        ></img>
                      </h4>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6" key={DemoData[2].id}>
                  <div className="single-demo">
                    <Link
                      to={
                        token
                          ? `${process.env.PUBLIC_URL}/${slugify(
                              DemoData[2].title
                            )}`
                          : ""
                      }
                      onClick={token ? "" : () => handleClickOpen("bg-remove")}
                    >
                      <span className="thumb">
                        <img
                          src={`${process.env.PUBLIC_URL}${DemoData[2].height_img}`}
                          alt={DemoData[2].title}
                        />
                      </span>
                      <h4 className="title">
                        Remove Backgound{" "}
                        <img
                          className="crownicon"
                          src={process.env.PUBLIC_URL + "/images/crown.png"}
                        ></img>
                      </h4>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6" key={DemoData[3].id}>
                  <div className="single-demo">
                    <Link to={process.env.PUBLIC_URL + "ai-analyze"}>
                      <span className="thumb">
                        <img
                          src={`${process.env.PUBLIC_URL}${DemoData[3].height_img}`}
                          alt={DemoData[3].title}
                        />
                      </span>
                      <h4 className="title"> Analyze Thumbnail</h4>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <ul className="shape-group list-unstyled">
              <li className="shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-35.png"}
                  alt="Shape"
                />
              </li>
              <li className="shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                  alt="Shape"
                />
              </li>
            </ul>
          </div>

          <div
            className="section section-padding bg-color-dark splash-features"
            id="splsh-features"
          >
            <div className="container featuresSection">
              <div className="section-heading heading-light-left">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <h2 className="title">We have Impressive Features</h2>
                  </div>
                  <div className="col-lg-5 col-md-8">
                    <p>
                      Our AI-powered platform excels at upscaling low-resolution
                      images, performing in-depth thumbnail analysis, and offers
                      the ability to effortlessly remove backgrounds and design
                      complete, polished thumbnails for your content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {FeatureData.slice(0, 3).map((data) => (
                  <div className="col-xl-4 col-md-6" key={data.id}>
                    <div className="services-grid">
                      <div className="thumbnail">
                        <img
                          className="homeicon"
                          src={process.env.PUBLIC_URL + data.icon}
                          alt="icon"
                        />
                      </div>
                      <div className="content">
                        <h5
                          className="title"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h5>
                        <p>{data.para}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <ul className="list-unstyled shape-group-10">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
                  alt="Circle"
                />
              </li>
            </ul>
          </div>
          <VideoTwo />

          <SplashCta />
          <FooterOne />
          <ThemeProvider theme={theme}>
            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  width: "2000px",
                },
              }}
              open={open}
              onClose={handleClose}
            >
              <div class="signup-form contact-form-box shadow-box dialog prompt">
                <div class="container signin-container">
                  <div class="header">
                    <h1 className="signin-title">Please Sign In!</h1>
                    <p>Registration is required to access this feature</p>
                  </div>
                  <div className="loadermain">
                    <div class="loader"></div>
                  </div>
                  <input
                    type="submit"
                    value="Sign In"
                    class="signup-btn dialogButton"
                    onClick={navigateFunction}
                  />
                  <input
                    type="button"
                    readonly
                    onClick={handleClose}
                    value="Cancel"
                    class="signup-btn dialogButton"
                  />
                </div>
              </div>
            </Dialog>
          </ThemeProvider>
        </main>
      </>
    );
  }
};

export default Splash;
