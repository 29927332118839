import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderTwo from "../../common/header/HeaderTwo";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import BannerUpscale from "../../component/banner/BannerUpscale";
import { supabase } from "../../utils/supabaseClient";
import { message } from "antd";
import HeaderLogggedIn from "../../common/header/HeaderLoggedIn";
import Loadinganim from "../../component/Loadinganim";
import FooterWithoutSocials from "../../common/footer/FooterWithoutSocials";

const Upscale = ({ token, setToken, credits, setCredits }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [countimg, setCountimg] = useState(0);

  async function fetchCredits(token) {
    try {
      const { data, error } = await supabase
        .from("users")
        .select("credits")
        .eq("email", token.user.email);
      if (error) throw error;
      if (data) {
        setCredits(data[0].credits);
        // console.log(credits);
      }
    } catch (error) {
      message.error(error.message + "");
    }
  }
  async function getSessionAuth() {
    // e.preventDefault()

    try {
      setIsLoading(true);

      const { data, error } = await supabase.auth.getSession();

      if (error) throw error;
      if (data.session) {
        // console.log(data.session);
        setToken(data.session);
        fetchCredits(data.session);

        // navigate('/auth-test')
      } else {
        navigate("/sign-in", { state: "upscale" });
      }
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getSessionAuth();
  }, []);

  if (credits == null) {
    return (
      <>
        <SEO title="Loading" />
        <ColorSwitcher />
        <main className="main-wrapper loadingpg">
          <HeaderTwo />
          <Loadinganim />

          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      </>
    );
  } else {
    if (isLoading || countimg < 1) {
      return (
        <>
          <SEO title="Loading" />
          <ColorSwitcher />
          <main className="main-wrapper loadingpg">
            {token ? (
              <HeaderLogggedIn
                email={token.user.email}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            ) : (
              <HeaderTwo />
            )}{" "}
            <Loadinganim />
            <div className="hide-slider">
              {" "}
              <BannerUpscale
                token={token}
                countimg={countimg}
                setCountimg={setCountimg}
                credits={credits}
                setCredits={setCredits}
              />
            </div>
            <ul className="shape-group-8 list-unstyled">
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                  alt="Line"
                />
              </li>
            </ul>
          </main>
        </>
      );
    } else {
      return (
        <>
          <SEO title="Upscale Image" />
          {/* <backgroundAnimation /> */}
          <ColorSwitcher />
          <main className="main-wrapper">
            {token ? (
              <HeaderLogggedIn
                email={token.user.email}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            ) : (
              <HeaderTwo />
            )}
            <BannerUpscale
              token={token}
              countimg={countimg}
              setCountimg={setCountimg}
              credits={credits}
              setCredits={setCredits}
            />

            {/* <div className="section section-padding">
            <div className="container">
              <SectionTitle
                subtitle="Boost your CTR..."
                title="elevate your thumnails <br> "
                description="make an impression today"
                textAlignment="heading-left mb--20 mb_md--70"
                textColor=""
              />
              <div className="row">
                <ServicePropOne
                  colSize="col-lg-4"
                  serviceStyle="service-style-2"
                  itemShow="3"
                  marginTop="yes"
                />
              </div>
            </div>
            <ul className="shape-group-7 list-unstyled">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                  alt="circle"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                  alt="Line"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                  alt="Line"
                />
              </li>
            </ul>
          </div> */}
            <FooterWithoutSocials parentClass="" />
          </main>
        </>
      );
    }
  }
};

export default Upscale;
