import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage,
} from "react-compare-slider";

const CompSliLoader = ({ style }) => {
  return (
    <>
      <div className="about-expert">
        <div className="thumbnail">
          <ReactCompareSlider
            className="slider-in"
            handle={
              <ReactCompareSliderHandle
                buttonStyle={{
                  display: "none",
                }}
                linesStyle={{
                  display: "none",
                }}
              />
            }
            // boundsPadding={20}

            itemTwo={
              <ReactCompareSliderImage
                src={process.env.PUBLIC_URL + "/animations/spinnerHome.svg"}
                style={{
                  filter: "",
                  width: "auto",
                  maxHeight: "405px",
                }}
                alt="two"
              />
            }
            style={{
              display: "flex",
              width: "100%",
              height: "auto",
              ...style,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CompSliLoader;
