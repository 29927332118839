import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderTwo from "../../common/header/HeaderTwo";
import HeaderLogggedIn from "../../common/header/HeaderLoggedIn";
import SEO from "../../common/SEO";
import BannerAnalyze from "../../component/banner/BannerAnalyze";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import FooterWithoutSocials from "../../common/footer/FooterWithoutSocials";
import Loadinganim from "../../component/Loadinganim";

const Analyse = ({ token, setToken, credits, setCredits }) => {
  const navigate = useNavigate();
  const [countimg, setCountimg] = useState(0);

  if (countimg < 1) {
    return (
      <>
        <SEO title="Loading" />
        <ColorSwitcher />
        <main className="main-wrapper loadingpg">
          {token ? (
            <HeaderLogggedIn
              email={token.user.email}
              setToken={setToken}
              credits={credits}
              setCredits={setCredits}
            />
          ) : (
            <HeaderTwo />
          )}{" "}
          <Loadinganim />
          <div className="hide-slider">
            <BannerAnalyze countimg={countimg} setCountimg={setCountimg} />
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      </>
    );
  } else {
    return (
      <>
        <SEO title="Analyze Thumbnail" />
        {/* <backgroundAnimation/> */}
        <ColorSwitcher />
        <main className="main-wrapper">
          {token ? (
            <HeaderLogggedIn
              email={token.user.email}
              setToken={setToken}
              credits={credits}
              setCredits={setCredits}
            />
          ) : (
            <HeaderTwo />
          )}
          <BannerAnalyze countimg={countimg} setCountimg={setCountimg} />

          {/* <div className="section section-padding">
          <div className="container">
            <SectionTitle
              subtitle="Boost your CTR..."
              title="elevate your thumbnails <br> "
              description="make an impression today"
              textAlignment="heading-left mb--20 mb_md--70"
              textColor=""
            />
            <div className="row">
              <ServicePropOne
                colSize="col-lg-4"
                serviceStyle="service-style-2"
                itemShow="3"
                marginTop="yes"
              />
            </div>
          </div>
          <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                alt="circle"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Line"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Line"
              />
            </li>
          </ul>
        </div> */}
          <FooterWithoutSocials parentClass="" />
        </main>
      </>
    );
  }
};

export default Analyse;
