import React from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import Nav from "./Nav";
import Logo from "../../elements/logo/Logo";
import MobileNav from "./MobileNav";

const MobileMenu = ({ MobileHandler }) => {
  return (
    <div className="mobilemenu-popup " onClick={MobileHandler}>
      <div className="mobilemenu-inner mob">
        <div className="mobilemenu-header header-navbar mob">
          <div className="mobile-nav-logo header-logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img
                className="light-mode"
                src={process.env.PUBLIC_URL + "/images/ImpressionsLogo_l.svg"}
                alt="Site Logo"
              />
              <img
                className="dark-mode1 dark-mode12"
                src={process.env.PUBLIC_URL + "/images/ImpressionsLogo_d.svg"}
                alt="Site Logo"
              />
            </Link>
          </div>

          <Link className="profileicon" to={"/sign-in"}>
            <img
              className="profileicon-light"
              src={process.env.PUBLIC_URL + "images/profilel.png"}
            />
            <img
              className="profileicon-dark"
              src={process.env.PUBLIC_URL + "images/profiled.png"}
            />
            <img
              className="profileicon-imp"
              src={process.env.PUBLIC_URL + "images/profilei.png"}
            />
          </Link>
        </div>
        <div className="mobilemenu-body mob">
          <MobileNav />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
