import React from "react";
import { useState, useEffect } from "react";
import FooterOne from "../common/footer/FooterOne";
import SEO from "../common/SEO";
import BcrumbBannerThree from "../elements/breadcrumb/BcrumbBannerThree";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import AboutThree from "../component/about/AboutThree";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderLogggedIn from "../common/header/HeaderLoggedIn";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Loadinganim from "../component/Loadinganim";

const AboutUs = ({ token, setToken, credits, setCredits }) => {
  const [count, setCount] = useState(0);
  if (count < 2) {
    return (
      <>
        <SEO title="Loading" />
        <ColorSwitcher />
        <main className="main-wrapper loadingpg">
          {token ? (
            <HeaderLogggedIn
              email={token.user.email}
              setToken={setToken}
              credits={credits}
              setCredits={setCredits}
            />
          ) : (
            <HeaderTwo />
          )}
          <Loadinganim />
          <div className="hide-slider">
            <BcrumbBannerTwo
              title="Welcome to Impressions"
              paragraph="With our AI-powered platform, you can effortlessly upscale low-resolution images, conduct thorough analysis of thumbnails, and even remove backgrounds with ease. <br/><br/> Additionally, our platform offers the capability to design fully polished and complete thumbnails for your content, ensuring a professional and captivating visual representation."
              mainThumb="/images/banner/ytl33.png"
              count={count}
              setCount={setCount}
            />
            <BcrumbBannerThree
              mainThumb="/images/others/demonitized1.png"
              count={count}
              setCount={setCount}
            />
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      </>
    );
  } else {
    return (
      <>
        <SEO title="About us" />
        <ColorSwitcher />
        <main className="main-wrapper">
          {token ? (
            <HeaderLogggedIn
              email={token.user.email}
              setToken={setToken}
              credits={credits}
              setCredits={setCredits}
            />
          ) : (
            <HeaderTwo />
          )}
          <BreadCrumbOne title="" page="About Us" />

          <BcrumbBannerTwo
            title="Welcome to Impressions"
            paragraph="With our AI-powered platform, you can effortlessly upscale low-resolution images, conduct thorough analysis of thumbnails, and even remove backgrounds with ease. <br/><br/> Additionally, our platform offers the capability to design fully polished and complete thumbnails for your content, ensuring a professional and captivating visual representation."
            mainThumb="/images/banner/ytl33.png"
            count={count}
            setCount={setCount}
          />

          {/* <ProcessOne /> */}

          {/* <BcrumbBannerTwo
          title="How do we rate the thumbnails?"
          paragraph="We run the thumnails through various AI models to analyse them and ensure the thumbnails are more likely to please the youtube algorithm and thus have a greater chance of having a higher CTR. We also check if the thumbnails are safe to search and if they might get restricted by Google."
          mainThumb="/images/others/demonitized.png"
        /> */}

          <AnimationOnScroll
            animateIn="slideInRight"
            duration={1}
            animateOnce={true}
          >
            <BcrumbBannerThree
              mainThumb="/images/others/demonitized1.png"
              count={count}
              setCount={setCount}
            />
          </AnimationOnScroll>
          {/* <AboutFour />               */}
          <AboutThree />

          <FooterOne parentClass="" />
        </main>
      </>
    );
  }
};

export default AboutUs;
