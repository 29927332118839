import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { supabase } from "../../utils/supabaseClient";

const MobileNavLoggedIn = ({ setToken }) => {
  const navigate = useNavigate();

  function handleLogout(e) {
    e.preventDefault();
    sessionStorage.removeItem("token");
    signout();
  }

  async function signout() {
    const { error } = await supabase.auth.signOut();
    setToken("");
    await navigate("/sign-in");
  }
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu mainmenumobile profileiconul">
        <li className="itemsnav">
          <Link to={process.env.PUBLIC_URL + "/design-thumbnail"}>
            Design Thumbnail{" "}
            <img
              className="crownicon"
              src={process.env.PUBLIC_URL + "/images/crown.png"}
            ></img>
          </Link>
        </li>

        <li className="itemsnav">
          <Link to={process.env.PUBLIC_URL + "/upscale"}>
            Upscale Image{" "}
            <img
              className="crownicon"
              src={process.env.PUBLIC_URL + "/images/crown.png"}
            ></img>
          </Link>
        </li>
        <li className="itemsnav">
          <Link to={process.env.PUBLIC_URL + "/bg-remove"}>
            Remove Background{" "}
            <img
              className="crownicon"
              src={process.env.PUBLIC_URL + "/images/crown.png"}
            ></img>
          </Link>
        </li>
        <li className="itemsnav">
          <Link to={process.env.PUBLIC_URL + "/ai-analyze"}>
            Analyze Thumbnail
          </Link>
        </li>

        <li className="itemsnav">
          <Link to={"/about-us"}>About Us</Link>
        </li>
        <li className="itemsnav">
          <Link to={"/contact"}>Contact</Link>
        </li>
        <li className="itemsnav">
          <Link to={"/pricing"}>Pricing</Link>
        </li>
        <li className="itemsnav">
          <Link to={"/profile"}>
            {" "}
            <b>Profile</b>{" "}
          </Link>
        </li>
        <li className="itemsnav">
          <Link onClick={handleLogout} to={"/sign-in"}>
            <b>Log Out</b>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavLoggedIn;
