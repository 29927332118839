import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderTwo from "../common/header/HeaderTwo";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import HeaderLogggedIn from "../common/header/HeaderLoggedIn";

const CookiePolicy = ({ token, setToken, credits, setCredits }) => {
  return (
    <>
      <SEO title="Cookie Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        {token ? (
          <HeaderLogggedIn
            email={token.user.email}
            setToken={setToken}
            credits={credits}
            setCredits={setCredits}
          />
        ) : (
          <HeaderTwo />
        )}
        <BreadCrumbOne title="Cookie Policy" page="Cookie Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">
                      This Cookie policy was updated on June 6th, 2023.
                    </h5>
                  </div>
                  <div id="wbars_all">
                    <div class="iub_container iub_base_container">
                      <div id="wbars">
                        <div class="iub_content legal_pp">
                          <div class="one_line_col">
                            {/* <h2>Cookie Policy of www.impressions.one</h2> */}

                            <p>
                              This document informs Users about the technologies
                              that help this Application to achieve the purposes
                              described below. Such technologies allow the Owner
                              to access and store information (for example by
                              using a Cookie) or use resources (for example by
                              running a script) on a User’s device as they
                              interact with this Application.
                            </p>
                            <p>
                              For simplicity, all such technologies are defined
                              as "Trackers" within this document – unless there
                              is a reason to differentiate.
                              <br />
                              For example, while Cookies can be used on both web
                              and mobile browsers, it would be inaccurate to
                              talk about Cookies in the context of mobile apps
                              as they are a browser-based Tracker. For this
                              reason, within this document, the term Cookies is
                              only used where it is specifically meant to
                              indicate that particular type of Tracker.
                            </p>

                            <p>
                              Some of the purposes for which Trackers are used
                              may also require the User's consent. Whenever
                              consent is given, it can be freely withdrawn at
                              any time following the instructions provided in
                              this document.
                            </p>

                            <p>
                              This Application only uses Trackers managed
                              directly by the Owner (so-called “first-party”
                              Trackers).
                              <br />
                              The validity and expiration periods of first-party
                              Cookies and other similar Trackers may vary
                              depending on the lifetime set by the Owner. Some
                              of them expire upon termination of the User’s
                              browsing session.
                            </p>

                            <h5 class="iub-purpose-stack iub-purpose-stack-1">
                              Activities strictly necessary for the operation of
                              this Application and delivery of the Service
                            </h5>
                            <p>
                              This Application uses so-called “technical”
                              Cookies and other similar Trackers to carry out
                              activities that are strictly necessary for the
                              operation or delivery of the Service.
                            </p>

                            <h5
                              class="iub-subheading iub-subheading-manage-gdpr"
                              id="manage_cookies"
                            >
                              How to manage preferences and provide or withdraw
                              consent
                            </h5>
                            <p>
                              There are various ways to manage Tracker related
                              preferences and to provide and withdraw consent,
                              where relevant:
                            </p>
                            <p>
                              Users can manage preferences related to Trackers
                              from directly within their own device settings,
                              for example, by preventing the use or storage of
                              Trackers.
                            </p>
                            <p>
                              Additionally, whenever the use of Trackers is
                              based on consent, Users can provide or withdraw
                              such consent by setting their preferences within
                              the cookie notice or by updating such preferences
                              accordingly via the relevant consent-preferences
                              widget, if available.
                            </p>
                            <p>
                              It is also possible, via relevant browser or
                              device features, to delete previously stored
                              Trackers, including those used to remember the
                              User’s initial consent.
                            </p>
                            <p>
                              Other Trackers in the browser’s local memory may
                              be cleared by deleting the browsing history.
                            </p>

                            <h5>Locating Tracker Settings</h5>
                            <p>
                              Users can, for example, find information about how
                              to manage Cookies in the most commonly used
                              browsers at the following addresses:
                            </p>
                            <ul>
                              <li>
                                <a
                                  rel="noopener nofollow"
                                  target="_blank"
                                  href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"
                                >
                                  Google Chrome
                                </a>
                              </li>
                              <li>
                                <a
                                  rel="noopener nofollow"
                                  target="_blank"
                                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                                >
                                  Mozilla Firefox
                                </a>
                              </li>
                              <li>
                                <a
                                  rel="noopener nofollow"
                                  target="_blank"
                                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/"
                                >
                                  Apple Safari
                                </a>
                              </li>
                              <li>
                                <a
                                  rel="noopener nofollow"
                                  target="_blank"
                                  href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"
                                >
                                  Microsoft Internet Explorer
                                </a>
                              </li>
                              <li>
                                <a
                                  rel="noopener nofollow"
                                  target="_blank"
                                  href="https://support.microsoft.com/en-us/help/4027947"
                                >
                                  Microsoft Edge
                                </a>
                              </li>
                              <li>
                                <a
                                  rel="noopener nofollow"
                                  target="_blank"
                                  href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing"
                                >
                                  Brave
                                </a>
                              </li>
                              <li>
                                <a
                                  rel="noopener nofollow"
                                  target="_blank"
                                  href="https://help.opera.com/en/latest/web-preferences/#cookies"
                                >
                                  Opera
                                </a>
                              </li>
                            </ul>
                            <p>
                              Users may also manage certain categories of
                              Trackers used on mobile apps by opting out through
                              relevant device settings such as the device
                              advertising settings for mobile devices, or
                              tracking settings in general (Users may open the
                              device settings and look for the relevant
                              setting).
                            </p>

                            <h5>Consequences of denying consent</h5>
                            <p>
                              Users are free to decide whether or not to grant
                              consent. However, please note that Trackers help
                              this Application to provide a better experience
                              and advanced functionalities to Users (in line
                              with the purposes outlined in this document).
                              Therefore, in the absence of the User's consent,
                              the Owner may be unable to provide related
                              features.
                            </p>

                            <h5
                              class="iub-subheading iub-subheading-owner-and-data-controller"
                              id="owner_of_the_data"
                            >
                              Owner and Data Controller
                            </h5>
                            <p>Impressions.one, Online Service</p>
                            <p>
                              <strong>Owner contact email:</strong>{" "}
                              admin@impressions.one
                            </p>

                            <p>
                              Given the objective complexity surrounding
                              tracking technologies, Users are encouraged to
                              contact the Owner should they wish to receive any
                              further information on the use of such
                              technologies by this Application.
                            </p>
                          </div>

                          <div class="one_line_col">
                            <div class="box_primary box_10 definitions expand">
                              <h5 class="expand-click w_icon_24 icon_ribbon">
                                Definitions and legal references
                              </h5>
                              <div class="expand-content">
                                <h5>Personal Data (or Data)</h5>
                                <p>
                                  Any information that directly, indirectly, or
                                  in connection with other information —
                                  including a personal identification number —
                                  allows for the identification or
                                  identifiability of a natural person.
                                </p>

                                <h5>Usage Data</h5>
                                <p>
                                  Information collected automatically through
                                  this Application (or third-party services
                                  employed in this Application), which can
                                  include: the IP addresses or domain names of
                                  the computers utilized by the Users who use
                                  this Application, the URI addresses (Uniform
                                  Resource Identifier), the time of the request,
                                  the method utilized to submit the request to
                                  the server, the size of the file received in
                                  response, the numerical code indicating the
                                  status of the server's answer (successful
                                  outcome, error, etc.), the country of origin,
                                  the features of the browser and the operating
                                  system utilized by the User, the various time
                                  details per visit (e.g., the time spent on
                                  each page within the Application) and the
                                  details about the path followed within the
                                  Application with special reference to the
                                  sequence of pages visited, and other
                                  parameters about the device operating system
                                  and/or the User's IT environment.
                                </p>

                                <h5>User</h5>
                                <p>
                                  The individual using this Application who,
                                  unless otherwise specified, coincides with the
                                  Data Subject.
                                </p>

                                <h5>Data Subject</h5>
                                <p>
                                  The natural person to whom the Personal Data
                                  refers.
                                </p>

                                <h5>Data Processor (or Processor)</h5>
                                <p>
                                  The natural or legal person, public authority,
                                  agency or other body which processes Personal
                                  Data on behalf of the Controller, as described
                                  in this privacy policy.
                                </p>

                                <h5>Data Controller (or Owner)</h5>
                                <p>
                                  The natural or legal person, public authority,
                                  agency or other body which, alone or jointly
                                  with others, determines the purposes and means
                                  of the processing of Personal Data, including
                                  the security measures concerning the operation
                                  and use of this Application. The Data
                                  Controller, unless otherwise specified, is the
                                  Owner of this Application.
                                </p>

                                <h5>This Application</h5>
                                <p>
                                  The means by which the Personal Data of the
                                  User is collected and processed.
                                </p>

                                <h5>Service</h5>
                                <p>
                                  The service provided by this Application as
                                  described in the relative terms (if available)
                                  and on this site/application.
                                </p>

                                <h5>European Union (or EU)</h5>
                                <p>
                                  Unless otherwise specified, all references
                                  made within this document to the European
                                  Union include all current member states to the
                                  European Union and the European Economic Area.
                                </p>

                                <h5>Cookie</h5>
                                <p>
                                  Cookies are Trackers consisting of small sets
                                  of data stored in the User's browser.
                                </p>

                                <h5>Tracker</h5>
                                <p>
                                  Tracker indicates any technology - e.g
                                  Cookies, unique identifiers, web beacons,
                                  embedded scripts, e-tags and fingerprinting -
                                  that enables the tracking of Users, for
                                  example by accessing or storing information on
                                  the User’s device.
                                </p>

                                <hr />

                                <h5>Legal information</h5>
                                <p>
                                  This privacy statement has been prepared based
                                  on provisions of multiple legislations,
                                  including Art. 13/14 of Regulation (EU)
                                  2016/679 (General Data Protection Regulation).
                                </p>
                                <p>
                                  This privacy policy relates solely to this
                                  Application, if not stated otherwise within
                                  this document.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="iub_footer">
                            <p>Latest update: June 06, 2023</p>

                            <p>
                              <a
                                target="_top"
                                href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator"
                                title="iubenda - Privacy Policy generator"
                              >
                                iubenda
                              </a>{" "}
                              hosts this content and only collects{" "}
                              <a
                                target="_top"
                                href="//www.iubenda.com/privacy-policy/65675001"
                              >
                                the Personal Data strictly necessary
                              </a>{" "}
                              for it to be provided.
                            </p>

                            <a
                              href="//www.iubenda.com/privacy-policy/63503516/legal?from_cookie_policy=true"
                              class="show_comp_link iframe-preserve an-preserve"
                            >
                              Show the complete Privacy Policy in iubenda
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default CookiePolicy;
