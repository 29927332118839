import React, { useEffect, useState, useRef } from "react";
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import { Link } from "react-router-dom";
import { supabase } from "../../utils/supabaseClient";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faLock,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { message } from "antd";
import Dialog from "@mui/material/Dialog";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Loadinganim from "../../component/Loadinganim";

let theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "Poppins"].join(","),
  },
  palette: {
    primary: {
      main: "#00adb4",
    },
    secondary: {
      main: "#00adb4",
    },
  },
});

const SignIn = ({ setToken }) => {
  const location = useLocation();

  const [pageToken, setPageToken] = useState(location.state);

  const form = useRef();

  const [open, setOpen] = useState(false);
  const [promptEmail, setPromptEmail] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingDialog, setLoadingDialog] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // console.log(formData);

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
      });

      if (error) throw error;
      // console.log(data);
      setToken(data);
      if (pageToken == null) {
        navigate("/");
      } else {
        navigate("/" + pageToken);
      }
    } catch (error) {
      message.error(error.message + "");
    } finally {
      setLoading(false);
    }
  }

  async function handleResetPassword(e) {
    e.preventDefault();

    try {
      setOpen(false);
      setLoading(true);
      const { data, error } = await supabase.auth.resetPasswordForEmail(
        promptEmail,
        {
          redirectTo: "https://www.impressions.one/forgot-password",
        }
      );
      if (error) throw error;
      // console.log(session)
      navigate("/reset-pw", { state: true });
    } catch (error) {
      message.error(error.message + "");
    } finally {
      setLoading(false);
    }
  }
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  async function handleSubmitGoogle(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },

          redirectTo: "https://www.impressions.one/" + pageToken,
        },
      });

      if (error) throw error;
      // console.log(data);
      // setToken(data.user)
      // navigate('/auth-test')
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitGithub(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "github",
        options: {
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },
          redirectTo: "https://www.impressions.one/" + pageToken,
        },
      });

      if (error) throw error;
      // console.log(data);
      // setToken(data.user)
      // navigate('/auth-test')
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitDiscord(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "Discord",
        options: {
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },
          redirectTo: "https://www.impressions.one/" + pageToken,
        },
      });

      if (error) throw error;
      // console.log(data);
      // setToken(data.user)
      // navigate('/auth-test')
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getSessionAuth() {
    // e.preventDefault()

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.getSession();

      if (error) throw error;
      if (data.session) {
        // console.log(data.session);
        setToken(data.session);

        if (pageToken == null) {
          navigate("/");
        } else {
          navigate("/" + pageToken);
        }
      }
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getSessionAuth();
    if (pageToken == null) {
      setPageToken("");
    } else {
      setPageToken(pageToken);
    }
  }, []);

  if (loading) {
    return (
      <>
        <SEO title="Loading" />
        <ColorSwitcher />
        <main className="main-wrapper loadingpg">
          <HeaderTwo /> <Loadinganim />
          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      </>
    );
  } else {
    return (
      <>
        <SEO title="Sign In" />
        <ColorSwitcher />
        {loading ? (
          <main className="main-wrapper loadingpg">
            <HeaderTwo />
            <Loadinganim />
            <ul className="shape-group-8 list-unstyled">
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                  alt="Line"
                />
              </li>
            </ul>
          </main>
        ) : (
          <main className="main-wrapper">
            <HeaderTwo />
            <div className="row row-signin">
              <div className="coming-soon-area onepage-screen-area">
                <div class="signup-form contact-form-box shadow-box">
                  <div class="container signin-container">
                    <div class="header">
                      <h1 className="signin-title">Sign In</h1>
                      <p>Sign in to your account!</p>
                    </div>

                    <form onSubmit={handleSubmit}>
                      <div class="input">
                        <i>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </i>
                        <input
                          required
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleChange}
                        />
                      </div>

                      <div class="input">
                        <i>
                          <FontAwesomeIcon icon={faLock} />
                        </i>
                        <input
                          required
                          type={passwordShown ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          onChange={handleChange}
                        />
                        {passwordShown ? (
                          <i className="eye">
                            <FontAwesomeIcon
                              onClick={togglePassword}
                              icon={faEye}
                            />
                          </i>
                        ) : (
                          <i className="eye">
                            <FontAwesomeIcon
                              onClick={togglePassword}
                              icon={faEyeSlash}
                            />
                          </i>
                        )}
                      </div>

                      <input class="signup-btn" type="submit" value="SIGN IN" />
                    </form>

                    <p className="signin-text">Or sign in with</p>
                    <div class="social-icons">
                      <i>
                        <img
                          onClick={handleSubmitGithub}
                          className="light-version-logo google"
                          src={process.env.PUBLIC_URL + "/images/github2.svg"}
                          alt="logo"
                        />
                      </i>
                      <i>
                        {/* <SocialIcon url="https://google.com/" onClick={handleSubmitGoogle} /> */}
                        <img
                          onClick={handleSubmitGoogle}
                          className="light-version-logo google"
                          src={process.env.PUBLIC_URL + "/images/google1.svg"}
                          alt="logo"
                        />
                      </i>
                      <i>
                        <img
                          onClick={handleSubmitDiscord}
                          className="light-version-logo google"
                          src={process.env.PUBLIC_URL + "/images/discord1.svg"}
                          alt="logo"
                        />
                      </i>
                    </div>
                    <p className="signin-text">
                      Forgot password?{" "}
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#00adb4",
                        }}
                        onClick={handleClickOpen}
                        className="signin-link"
                        to={"/sign-in"}
                      >
                        Reset Password
                      </Link>
                    </p>

                    <p className="signin-text">
                      Don't have an account?{" "}
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#00adb4",
                        }}
                        className="signin-link"
                        to={"/sign-up"}
                      >
                        Sign Up
                      </Link>
                    </p>
                  </div>
                </div>

                <ul className="shape-group-8 list-unstyled">
                  <li className="shape shape-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                      alt="Line"
                    />
                  </li>
                </ul>
              </div>
            </div>

            <FooterOne parentClass="" />

            <ThemeProvider theme={theme}>
              <Dialog
                PaperProps={{
                  style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    width: "2000px",
                  },
                }}
                open={open}
                onClose={handleClose}
              >
                <div class="signup-form contact-form-box shadow-box dialog prompt">
                  {/* {loadingDialog ? (

                <ThemeProvider theme={theme}>
                  <Box sx={{ width: '100%', paddingBottom: '15px' }}>
                    <LinearProgress sx={{ height: '17px', borderRadius: '8px' }} variant="buffer" value={progress} valueBuffer={buffer} />
                  </Box>
                </ThemeProvider>

              ) : ( */}

                  <div class="container signin-container">
                    <div class="header">
                      <h1 className="signin-title">Reset Password</h1>
                      <p>Please enter your email below!</p>
                    </div>

                    <form
                      classname="axil-contact-form prompt"
                      onSubmit={handleResetPassword}
                    >
                      <div class="input prompt">
                        <i>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </i>
                        <input
                          required
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={(e) => setPromptEmail(e.target.value)}
                        />
                      </div>

                      <input
                        type="submit"
                        value="OK"
                        class="signup-btn dialogButton"
                      />
                      <input
                        type="button"
                        readonly
                        onClick={handleClose}
                        value="CANCEL"
                        class="signup-btn dialogButton"
                      />
                    </form>
                  </div>
                  {/* )} */}
                </div>
              </Dialog>
            </ThemeProvider>
          </main>
        )}
      </>
    );
  }
};

export default SignIn;
