import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import Scroll from "./component/Scroll";
import "bootstrap/dist/css/bootstrap.min.css";
import { message } from "antd";
import { supabase } from "./utils/supabaseClient";
import useLocalStorage from "use-local-storage";

// Pages
import Splash from "./pages/Splash";
import AboutUs from "./pages/AboutUs";
import Results from "./pages/Results";
import PricingTable from "./pages/PricingTable";
import Typography from "./pages/Typography";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import TermsOfUse from "./pages/TermsOfUse";
import ErrorPage404 from "./pages/404";
import ErrorPage429 from "./pages/429";
import Profile from "./pages/profile/profile";
import Loading from "./pages/loading";

// Auth
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ForgotPw from "./pages/auth/ForgotPw";
import ResetPwWait from "./pages/auth/ResetPwWait";
import SignUpWait from "./pages/auth/SignUpWait";

//Generate Thumbnail
import ThumbGen from "./pages/thumbGen/thumbGen";
import ThumbGenFace from "./pages/thumbGen/thumbGenFace";
import ThumbGenFinal from "./pages/thumbGen/thumbGenFinal";

// Css Import
import "./assets/scss/app.scss";

// Services
import Analyse from "./pages/analyse/analyse";
import Upscale from "./pages/upscale/upscale";
import BgRemove from "./pages/bg-remove/bg-remove";
import UpscaleFinal from "./pages/upscale/upscale-final";
import BGRFinal from "./pages/bg-remove/bg-remove-final";

const App = () => {
  const [token, setToken] = useState(false);
  const [credits, setCredits] = useState();

  async function fetchCredits(token) {
    try {
      const { data, error } = await supabase
        .from("users")
        .select("credits")
        .eq("email", token.user.email);
      if (error) throw error;
      if (data) {
        setCredits(data[0].credits);
        // console.log(credits);
      }
    } catch (error) {
      message.error(error.message + "");
    }
  }

  if (token) {
    sessionStorage.setItem("token", JSON.stringify(token));
  }

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      let data = JSON.parse(sessionStorage.getItem("token"));
      setToken(data);
      fetchCredits(data);
    }
  }, []);

  useEffect(() => {
    if (token) {
      fetchCredits(token);
    }
  }, [credits]);

  console.clear();
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route
            path={process.env.PUBLIC_URL + "/"}
            element={
              <Splash
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          {token ? (
            <Route
              path={process.env.PUBLIC_URL + "/sign-in"}
              element={
                <Splash
                  token={token}
                  setToken={setToken}
                  credits={credits}
                  setCredits={setCredits}
                />
              }
            />
          ) : (
            <Route
              path={process.env.PUBLIC_URL + "/sign-in"}
              element={<SignIn setToken={setToken} />}
            />
          )}
          {token ? (
            <Route
              path={process.env.PUBLIC_URL + "/sign-up"}
              element={
                <Splash
                  token={token}
                  setToken={setToken}
                  credits={credits}
                  setCredits={setCredits}
                />
              }
            />
          ) : (
            <Route
              path={process.env.PUBLIC_URL + "/sign-up"}
              element={<SignUp setToken={setToken} />}
            />
          )}
          {token ? (
            <Route
              path={process.env.PUBLIC_URL + "/forgot-password"}
              element={<SignIn setToken={setToken} />}
            />
          ) : (
            <Route
              path={process.env.PUBLIC_URL + "/forgot-password"}
              element={<ForgotPw />}
            />
          )}
          {token ? (
            <Route
              path={process.env.PUBLIC_URL + "/reset-pw"}
              element={<SignIn setToken={setToken} />}
            />
          ) : (
            <Route
              path={process.env.PUBLIC_URL + "/reset-pw"}
              element={<ResetPwWait />}
            />
          )}
          {token ? (
            <Route
              path={process.env.PUBLIC_URL + "/signup-wait"}
              element={<SignIn setToken={setToken} />}
            />
          ) : (
            <Route
              path={process.env.PUBLIC_URL + "/signup-wait"}
              element={<SignUpWait />}
            />
          )}
          <Route
            path={process.env.PUBLIC_URL + "/profile"}
            element={
              <Profile
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={"/ai-analyze"}
            element={
              <Analyse
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/design-thumbnail"}
            element={
              <ThumbGen
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />

          <Route
            path={process.env.PUBLIC_URL + "/upscale"}
            element={
              <Upscale
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/upscale-final"}
            element={
              <UpscaleFinal
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          {/* <Route path={process.env.PUBLIC_URL + "/stripe-webhook"} /> */}
          <Route
            path={process.env.PUBLIC_URL + "/bg-remove"}
            element={
              <BgRemove
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/bg-remove-final"}
            element={
              <BGRFinal
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/design-thumbnail-bg-remove"}
            element={
              <ThumbGenFace
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/design-thumbnail-final"}
            element={
              <ThumbGenFinal
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={"/about-us"}
            element={
              <AboutUs
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={"/results"}
            element={
              <Results
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/pricing"}
            element={
              <PricingTable
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/typography"}
            element={<Typography credits={credits} setCredits={setCredits} />}
          />
          <Route
            path={"/contact"}
            element={
              <Contact
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/404"}
            element={
              <ErrorPage404
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/429"}
            element={
              <ErrorPage429
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/privacy-policy"}
            element={
              <PrivacyPolicy
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/cookie-policy"}
            element={
              <CookiePolicy
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
          <Route
            path={process.env.PUBLIC_URL + "/terms-use"}
            element={
              <TermsOfUse
                token={token}
                setToken={setToken}
                credits={credits}
                setCredits={setCredits}
              />
            }
          />
        </Routes>
      </ScrollToTop>
      <Scroll showBelow={250} />
    </Router>
  );
};

export default App;
