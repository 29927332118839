import React, { useEffect } from "react";

const ComparisonSliderHomeGen = ({ count, setCount }) => {
  const [className, setClassName] = React.useState();

  useEffect(() => {
    document.querySelector("video").play();
  }, []);

  return (
    <>
      <div className="about-expert ">
        <video
          id="vid"
          className={className}
          autoplay
          muted
          playsInline
          loop
          width="100%"
          height="100%"
          onLoadedData={() => setClassName("home-page-gif")}
        >
          <source
            src={"/images/homePageGraphics/designtngifhome.mp4"}
            type="video/mp4"
            width={100}
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </>
  );
};

export default ComparisonSliderHomeGen;
