import React from "react";
import { useEffect } from "react";
import { FaLightbulb, FaMoon } from "react-icons/fa";
import useLocalStorage from "use-local-storage";

const ColorSwitcher = () => {
  // const switchColor = () => {
  //   document.querySelector("body").classList.toggle("active-dark-mode");
  // };
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  // const defaultDark = true;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "active-dark-mode" : ""
  );
  const switchColor = () => {
    const newTheme = theme === "" ? "active-dark-mode" : "";
    setTheme(newTheme);
  };

  useEffect(() => {
    if (theme === "active-dark-mode") {
      document.querySelector("body").classList.add("active-dark-mode");
      document.querySelector("body").style.backgroundColor = "#212121";
    } else {
      document.querySelector("body").classList.remove("active-dark-mode");
      document.querySelector("body").style.backgroundColor = "#ffffff";
    }
  }, [theme]);

  return (
    <div className="my_switcher d-none d-lg-block">
      <button onClick={switchColor}>
        <span className="setColor dark">
          <FaLightbulb />
        </span>
        <span className="setColor light">
          <FaMoon />
        </span>
      </button>
    </div>
  );
};

export default ColorSwitcher;
