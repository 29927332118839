import React, { useState, useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link, useNavigate } from "react-router-dom";
import { message, Upload } from "antd";
import emailjs from "@emailjs/browser";
import { supabase } from "../../utils/supabaseClient";
const { Dragger } = Upload;

const BannerBGR = ({ token, countimg, setCountimg, credits, setCredits }) => {
  // const [src,setSrc] = useState(null)

  const [count, setCount] = useState(0);
  const [uidRemBG, setUidRemBG] = useState("");
  const navigate = useNavigate();
  const [creditsLeft, setCreditsLeft] = useState(credits);
  const [model, setModel] = useState("premium");
  const [creditsCost, setCreditsCost] = useState(4);

  async function updateCredits(token) {
    try {
      const { error } = await supabase
        .from("users")
        .update({ credits: creditsLeft })
        .eq("email", token.user.email);
      if (error) throw error;
    } catch (error) {
      message.error(error.message + "");
    }
  }

  async function fetchCredits(token) {
    try {
      const { data, error } = await supabase
        .from("users")
        .select("credits")
        .eq("email", token.user.email);
      if (error) throw error;
      if (data) {
        setCredits(data[0].credits);
        // console.log(data[0].credits);
      }
    } catch (error) {
      message.error(error.message + "");
    }
  }

  useEffect(() => {
    updateCredits(token);
    fetchCredits(token);
  }, [creditsLeft]);

  //fetch and subtract credits left every time the prop is called

  useEffect(() => {
    if (model == "regular") {
      setCreditsCost(1);
    } else if (model == "premium") {
      setCreditsCost(4);
    }
  }, [model]);

  const deductCredits = () => {
    // console.log("check how many credits");
    setCreditsLeft(creditsLeft - creditsCost);
    // console.log("subtract 1 credit");
  };

  //formdata for sending model name to backend
  const formData = new FormData();
  formData.append("model", model);

  const props = {
    name: "file",
    method: "POST",
    body: formData,
    data: {
      model: model,
    },
    crossOrigin: "anonymous",
    multiple: false,
    action: "https://www.impressions.one/uploadsRemBG",
    // action: "http://127.0.0.1:5000/uploadsRemBG",
    // action: "https://impressionsdotonev2test.onrender.com/uploadsRemBG",
    onChange(info) {
      const { status } = info.file;

      setCount(0);

      if (status === "done") {
        setCount(1);

        if (info.file.response[0] === "<") {
          emailjs
            .send(
              "service_e680d7j",
              "template_xtw7ojk",
              {
                to_name: "ADMIN",
                from_name: "SYSTEM",
                message: "429 error/rembg",
              },
              "TfuaKdQQ79zIC3Y4D"
            )
            .then(
              (result) => {
                // console.log(result.text);
              },
              (error) => {
                // console.log(error.text);
              }
            );
          navigate("/429");
        } else {
          setUidRemBG([info.file.response, info.file.name]);
        }

        message.success(`${info.file.name} file uploaded successfully.`);
        deductCredits();
        fetchCredits(token);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setCount(0);
    },
  };

  const handleDraggerClick = (e) => {
    message.warning("Not enough credits. Go to your profile page to get more.");
  };
  const [highlightRegular, setHighlightRegular] = useState(false);
  function HighlightItemRegular() {
    setModel("regular");
    setHighlightRegular(true);
    setHighlightPremium(false);
  }
  const [highlightPremium, setHighlightPremium] = useState(true);
  function HighlightItemPremium() {
    setModel("premium");
    setHighlightPremium(true);
    setHighlightRegular(false);
  }

  return (
    <div className="banner banner-style-2">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-content">
              <h1 className="title">
                Remove <br /> Image <br />
                Backgrounds
              </h1>
              <p className="banner-body">
                No need to learn complicated tools, effortlessly remove
                backgrounds from your images in just a few clicks.
                <br />
                <span
                  className={
                    highlightPremium ? "show-cost-highligted" : "show-cost"
                  }
                  onClick={HighlightItemPremium}
                >
                  Premium Cost: 4{" "}
                  <img
                    className="dollaricon"
                    src={process.env.PUBLIC_URL + "/images/token.png"}
                  ></img>
                </span>
                <span
                  className={
                    highlightRegular ? "show-cost-highligted" : "show-cost"
                  }
                  onClick={HighlightItemRegular}
                >
                  Regular Cost: 1{" "}
                  <img
                    className="dollaricon"
                    src={process.env.PUBLIC_URL + "/images/token.png"}
                  ></img>
                </span>
              </p>

              <div></div>

              <ul className="dragndropUL">
                <li>
                  {creditsLeft >= creditsCost ? (
                    <Dragger
                      {...props}
                      listType="picture"
                      maxCount={1}
                      className="drag-image"
                      accept=".png,.jpg,.jpeg,"
                    >
                      <img
                        className="icon"
                        src={process.env.PUBLIC_URL + "/images/up-arrow.png"}
                      />

                      <p className="upload-text">Select or drop your image</p>
                      <p className="ant-upload-hint"></p>
                    </Dragger>
                  ) : (
                    <Dragger
                      maxCount={1}
                      openFileDialogOnClick={false}
                      beforeUpload={handleDraggerClick}
                      className="drag-image"
                      listType="picture"
                    >
                      <div onClick={handleDraggerClick}>
                        <img
                          className="icon"
                          src={process.env.PUBLIC_URL + "/images/up-arrow.png"}
                        />

                        <p className="upload-text">Select or drop your image</p>
                        <p className="ant-upload-hint"></p>
                      </div>
                    </Dragger>
                  )}
                </li>

                <li>
                  {count === 0 ? null : (
                    <Link
                      to="/bg-remove-final"
                      state={uidRemBG}
                      className="axil-btn btn-fill-white btn-large bannerBtn"
                    >
                      Preview
                    </Link>
                  )}

                  {/* <button onClick={() => setCount(count + 1)}>Increment</button> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-18">
        <li className="shape shape-1 thumbgen-vector">
          <AnimationOnScroll
            animateIn="slideInRight"
            duration={1}
            animateOnce={true}
            delay={100}
          >
            <img
              className="subPageGraphicsbgr"
              src={process.env.PUBLIC_URL + "/images/banner/bgremove.png"}
              alt="Shape"
              onLoad={() => setCountimg(countimg + 1)}
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-2"></li>
        <li className="shape shape-3">
          <AnimationOnScroll
            animateIn="zoomIn"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-4">
          <AnimationOnScroll
            animateIn="zoomIn"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-5">
          <AnimationOnScroll
            animateIn="zoomIn"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-6">
          <AnimationOnScroll
            animateIn="zoomIn"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-7">
          <AnimationOnScroll
            animateIn="slideInDown"
            duration={1}
            animateOnce={true}
            delay={500}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-26.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
      </ul>
    </div>
  );
};

export default BannerBGR;
