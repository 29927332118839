import React from "react";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import Nav from "./Nav";
import Logo from "../../elements/logo/Logo";
import MobileNav from "./MobileNav";
import Avatar from "@mui/material/Avatar";
import MobileNavLoggedIn from "./MobileNavLoggedIn";
import { supabase } from "../../utils/supabaseClient";
import { message } from "antd";

const MobileMenuLoggedIn = ({
  setToken,
  email,
  MobileHandler,
  credits,
  setCredits,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  async function fetchCredits(email) {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("users")
        .select("credits")
        .eq("email", email);
      if (error) throw error;
      if (data) {
        setCredits(data[0].credits);
        // console.log(data[0].credits);
      }
    } catch (error) {
      message.error(error.message + "");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchCredits(email);
  }, []);
  return (
    <div className="mobilemenu-popup " onClick={MobileHandler}>
      <div className="mobilemenu-inner mob">
        <div className="mobilemenu-header header-navbar mob">
          <div className="mobile-nav-logo header-logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img
                className="light-mode"
                src={process.env.PUBLIC_URL + "/images/ImpressionsLogo_l.svg"}
                alt="Site Logo"
              />
              <img
                className="dark-mode1 dark-mode12"
                src={process.env.PUBLIC_URL + "/images/ImpressionsLogo_d.svg"}
                alt="Site Logo"
              />
            </Link>
          </div>
          <Link
            to={process.env.PUBLIC_URL + "/profile"}
            className="change-cursor credits-header-mobile"
          >
            {credits != null ? (
              <>
                <b>{credits}</b>
              </>
            ) : (
              <>
                <img className="creditloading" src="images/oval.svg" />
              </>
            )}{" "}
            <img
              className="dollaricon"
              src={process.env.PUBLIC_URL + "/images/token.png"}
            ></img>
          </Link>
          <Link to="/profile" className="change-cursor profileicon">
            <a className="change-cursor" target="_blank">
              <Avatar sx={{ bgcolor: "#00adb4", width: 35, height: 35 }}>
                {email[0].toUpperCase()}
              </Avatar>
            </a>
          </Link>
        </div>
        <div className="mobilemenu-body mob">
          <MobileNavLoggedIn setToken={setToken} />
        </div>
      </div>
    </div>
  );
};

export default MobileMenuLoggedIn;
