import React, { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import ComparisonSliderHomeGen from "../project/ComparisonSliderHomeGen";
import TabList from "../project/TabList";
import ComparisonSliderHomeUp from "../project/ComparisonSliderHomeUp";
import ComparisonSliderHomeAna from "../project/ComparisonSliderHomeAna";
import ComparisonSliderHomeBgr from "../project/ComparisonSliderHomeBgr";
import CompSliLoader from "../project/CompSliLoader";

const BannerOne = () => {
  async function scrollTo() {
    scroller.scrollTo("services", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  const [count, setCount] = useState(0);

  const [genRd, setGenRD] = useState(true);
  const [upRd, setUpRD] = useState(false);
  const [anaRd, setAnaRD] = useState(false);
  const [bgrRd, setBgrRD] = useState(false);

  return (
    <div className="banner banner-style-1">
      <div className="container">
        <div className="row align-items-end align-items-xl-start">
          <div className="col-lg-6">
            <div className="banner-content">
              <AnimationOnScroll
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}
              >
                <h1 className="title">
                  Elevate Your <span className="youtube">YouTube</span>
                  Thumbnails
                </h1>
                <span className="subtitle">
                  Leverage <b className="boldtext">AI</b> to{" "}
                  <b className="boldtext">Design</b>,{" "}
                  <b className="boldtext">Upscale</b> and
                  <b className="boldtext"> Analyze</b> your thumbnails
                </span>
                <Link
                  to=""
                  onClick={() => scrollTo()}
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="axil-btn btn-fill-primary btn-large get-started-btn"
                >
                  Get Started
                </Link>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-thumbnail">
              <AnimationOnScroll
                animateIn="zoomIn"
                duration={2}
                delay={300}
                animateOnce={true}
              >
                <div className=" sliderhome large-thumb">
                  <TabList
                    genRd={genRd}
                    upRd={upRd}
                    anaRd={anaRd}
                    bgrRd={bgrRd}
                    setGenRD={setGenRD}
                    setUpRD={setUpRD}
                    setAnaRD={setAnaRD}
                    setBgrRD={setBgrRD}
                  />
                  <div className="outer-slider">
                    <div className="top">
                      {genRd ? (
                        <ComparisonSliderHomeGen
                          count={count}
                          setCount={setCount}
                        />
                      ) : null}
                      {upRd ? (
                        <ComparisonSliderHomeUp
                          count={count}
                          setCount={setCount}
                        />
                      ) : null}
                      {anaRd ? (
                        <ComparisonSliderHomeAna
                          count={count}
                          setCount={setCount}
                        />
                      ) : null}
                      {bgrRd ? (
                        <ComparisonSliderHomeBgr
                          count={count}
                          setCount={setCount}
                        />
                      ) : null}
                    </div>
                    <div className="below">
                      <CompSliLoader />
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="slideInRight"
                duration={2}
                delay={300}
                animateOnce={true}
              >
                <div className="large-thumb-2 laptoppose">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/banner/laptop-poses.png"
                    }
                    alt="Laptop"
                  />
                </div>
              </AnimationOnScroll>
              <ul className="list-unstyled shape-group">
                <li className="shape shape-1">
                  <AnimationOnScroll
                    animateIn="slideInLeft"
                    duration={1}
                    delay={800}
                    animateOnce={true}
                  ></AnimationOnScroll>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-21">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-4">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-5">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-6">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-7">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"}
            alt="Bubble"
          />
        </li>
      </ul>
    </div>
  );
};

export default BannerOne;
