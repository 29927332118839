import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage,
} from "react-compare-slider";
import useMediaQuery from "@mui/material/useMediaQuery";
const ComparisonSliderHomeBgr = ({ style, count, setCount }) => {
  const checkMobile = useMediaQuery("(max-width:991px)");

  return (
    <>
      <div className="about-expert">
        <div className="thumbnail">
          <ReactCompareSlider
            className="slider-in"
            handle={
              <ReactCompareSliderHandle
                buttonStyle={{
                  backdropFilter: undefined,
                  background: "white",
                  border: 3,
                  color: "#333",
                }}
                linesStyle={{
                  background: "transparent",
                  color: "transparent",
                  boxShadow: 0,
                  border: 0,
                }}
              />
            }
            // boundsPadding={20}
            itemOne={
              <ReactCompareSliderImage
                src={"/images/homePageGraphics/bgrhome.png"}
                style={{
                  filter: "",
                  width: "auto",
                  maxHeight: "405px",
                }}
                alt="one"
                onLoad={() => setCount(count + 1)}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={"/images/homePageGraphics/bgrhomecomp.png"}
                style={{ filter: "", width: "auto", maxHeight: "405px" }}
                alt="two"
                onLoad={() => setCount(count + 1)}
              />
            }
            onlyHandleDraggable={checkMobile}
            style={{
              display: "flex",
              width: "100%",
              height: "auto",
              boxShadow:
                "0 0 30px 0 rgba(69, 196, 234, 0.15), 0 6px 12px 0 rgba(23, 210, 224, 0.15)",
              borderRadius: "20px",
              ...style,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ComparisonSliderHomeBgr;
