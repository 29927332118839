import React, { useEffect, useState } from "react";
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import { Link } from "react-router-dom";
import { supabase } from "../../utils/supabaseClient";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { message } from "antd";
import { createTheme } from "@mui/material/styles";
import Loadinganim from "../../component/Loadinganim";

let theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "Poppins"].join(","),
  },
  palette: {
    primary: {
      main: "#00adb4",
    },
    secondary: {
      main: "#00adb4",
    },
  },
});
const SignUp = ({ setToken }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });

  // console.log(formData);

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (formData.password == formData.confirmpassword) {
      if (formData.password.length >= 6) {
        try {
          setLoading(true);

          const { data, error } = await supabase.auth.signUp({
            email: formData.email,
            password: formData.password,
          });

          if (error) throw error;
          // console.log(data);
          if (data.user.identities.length == 0)
            throw message.error("User already exsits");
          message.success("Check your email for verification link");
          navigate("/signup-wait", { state: true });
        } catch (error) {
          if (error.message + "" != "undefined") {
            message.error(error.message + "");
          }
        } finally {
          setLoading(false);
        }
      } else {
        message.error("Password must be at least 6 characters");
      }
    } else {
      message.error("Password and Confirm Password does not match");
    }
  }

  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  async function handleSubmitGoogle(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },
        },
      });

      if (error) throw error;
      // console.log(data);
      // setToken(data.user)
      // navigate('/auth-test')
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitGithub(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "github",
        options: {
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },
        },
      });

      if (error) throw error;
      // console.log(data);
      // setToken(data.user)
      // navigate('/auth-test')
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitDiscord(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "Discord",
        options: {
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },
        },
      });

      if (error) throw error;
      // console.log(data);
      // setToken(data.user)
      // navigate('/auth-test')
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getSessionAuth() {
    // e.preventDefault()

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.getSession();

      if (error) throw error;
      if (data.session) {
        // console.log(data.session);
        setToken(data.session);

        navigate("/");
      }
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getSessionAuth();
  }, []);

  if (loading) {
    return (
      <>
        <SEO title="Loading" />
        <ColorSwitcher />
        <main className="main-wrapper loadingpg">
          <HeaderTwo /> <Loadinganim />
          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      </>
    );
  } else {
    return (
      <>
        <SEO title="Sign Up" />
        <ColorSwitcher />
        {loading ? (
          <main className="main-wrapper loadingpg">
            <HeaderTwo />
            <Loadinganim />
            <ul className="shape-group-8 list-unstyled">
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                  alt="Line"
                />
              </li>
            </ul>
          </main>
        ) : (
          <main className="main-wrapper">
            <HeaderTwo />
            <div className="row row-signin">
              <div className="coming-soon-area onepage-screen-area">
                <div class="signup-form contact-form-box shadow-box">
                  <div class="container">
                    <div class="header">
                      <h1 className="signin-title">Create an Account</h1>
                      <p>Get started for free!</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div class="input">
                        <i>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </i>
                        <input
                          required
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleChange}
                        />
                      </div>
                      <div class="input">
                        <i>
                          <FontAwesomeIcon icon={faLock} />
                        </i>
                        <input
                          required
                          type={passwordShown1 ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          onChange={handleChange}
                        />
                        {passwordShown1 ? (
                          <i className="eye">
                            <FontAwesomeIcon
                              onClick={togglePassword1}
                              icon={faEye}
                            />
                          </i>
                        ) : (
                          <i className="eye">
                            <FontAwesomeIcon
                              onClick={togglePassword1}
                              icon={faEyeSlash}
                            />
                          </i>
                        )}
                      </div>
                      <div class="input">
                        <i>
                          <FontAwesomeIcon icon={faLock} />
                        </i>
                        <input
                          required
                          type={passwordShown2 ? "text" : "password"}
                          placeholder="Confirm Password"
                          name="confirmpassword"
                          onChange={handleChange}
                        />
                        {passwordShown2 ? (
                          <i className="eye">
                            <FontAwesomeIcon
                              onClick={togglePassword2}
                              icon={faEye}
                            />
                          </i>
                        ) : (
                          <i className="eye">
                            <FontAwesomeIcon
                              onClick={togglePassword2}
                              icon={faEyeSlash}
                            />
                          </i>
                        )}
                      </div>
                      <input class="signup-btn" type="submit" value="SIGN UP" />
                    </form>
                    <p className="signin-text">Or sign up with</p>
                    <div class="social-icons">
                      <i>
                        <img
                          onClick={handleSubmitGithub}
                          className="light-version-logo google"
                          src={process.env.PUBLIC_URL + "/images/github2.svg"}
                          alt="logo"
                        />
                      </i>
                      <i>
                        {/* <SocialIcon url="https://google.com/" onClick={handleSubmitGoogle} /> */}
                        <img
                          onClick={handleSubmitGoogle}
                          className="light-version-logo google"
                          src={process.env.PUBLIC_URL + "/images/google1.svg"}
                          alt="logo"
                        />
                      </i>
                      <i>
                        <img
                          onClick={handleSubmitDiscord}
                          className="light-version-logo google"
                          src={process.env.PUBLIC_URL + "/images/discord1.svg"}
                          alt="logo"
                        />
                      </i>
                    </div>
                    <p className="signin-text">
                      Already have an account?{" "}
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#00adb4",
                        }}
                        classname="signin-link"
                        to={"/sign-in"}
                      >
                        Sign In
                      </Link>
                    </p>
                  </div>
                </div>

                <ul className="shape-group-8 list-unstyled">
                  <li className="shape shape-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                      alt="Line"
                    />
                  </li>
                </ul>
              </div>
            </div>

            <FooterOne parentClass="" />
          </main>
        )}
      </>
    );
  }
};

export default SignUp;
