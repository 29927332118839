import React from "react";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <footer className="footer-area footer-dark footer-bottom-stay">
      <div className="container">
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="https://www.impressions.one/">Impressions.one</a>
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <a href={"https://www.iubenda.com/privacy-policy/63503516"}>
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    {/* <a href="https://www.iubenda.com/privacy-policy/63503516/cookie-policy"> */}
                    <a href={process.env.PUBLIC_URL + "/terms-use"}>
                      Terms of Use
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTwo;
