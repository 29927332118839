import React from "react";
import { useState } from "react";
import saveAs from "file-saver";

const ThreeThumbnails = ({
  uid,
  selectedImageMain,
  setSelectedImageMain,
  count,
  setCount,
  colorBg,
  colorShow,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [newUid, setNewUid] = useState(uid);
  const [pos, setPos] = useState();
  async function setPositionDownload(position) {
    setPos(position);
    setIsLoading(false);

    await fetch("/getPosition", {
      method: "POST",
      body: JSON.stringify(
        position +
          "#" +
          newUid[0].substring(0, newUid[0].length - 4) +
          "#" +
          (colorShow ? colorBg : selectedImageMain)
      ),
    })
      .then((data) => {
        if (data.status == 200) {
          setIsLoading(true);
          let bgImageName = "";
          colorShow
            ? (bgImageName = colorBg.split("#").pop() + ".png")
            : (bgImageName = selectedImageMain.split("/").pop());

          if (position == "ALL") {
            downloadZip(
              "https://storage.googleapis.com/analysisimagebucket/" +
                newUid[0].substring(0, newUid[0].length - 4) +
                bgImageName.substring(0, bgImageName.length - 4) +
                "tns.zip"
            );
          } else if (position == "LEFT") {
            download(
              "https://storage.googleapis.com/analysisimagebucket/" +
                newUid[0].substring(0, newUid[0].length - 4) +
                bgImageName.substring(0, bgImageName.length - 4) +
                "tnl.png"
            );
          } else if (position == "RIGHT") {
            download(
              "https://storage.googleapis.com/analysisimagebucket/" +
                newUid[0].substring(0, newUid[0].length - 4) +
                bgImageName.substring(0, bgImageName.length - 4) +
                "tnr.png"
            );
          } else if (position == "CENTER") {
            download(
              "https://storage.googleapis.com/analysisimagebucket/" +
                newUid[0].substring(0, newUid[0].length - 4) +
                bgImageName.substring(0, bgImageName.length - 4) +
                "tnc.png"
            );
          }
        }
      })
      .catch((err) => {
        // console.log(err.message);
      });
  }

  function download(urlArr) {
    (async () => {
      let name = newUid[1];
      let blob = await fetch(urlArr).then((r) => r.blob());
      saveAs(blob, name);
    })();
  }
  function downloadZip(urlArr) {
    (async () => {
      let name = "impressionsTNS.zip";
      let blob = await fetch(urlArr).then((r) => r.blob());
      saveAs(blob, name);
    })();
  }

  return (
    <div className="section section-padding pb--80 pb_lg--40 pb_md--20 pb_sm--0">
      <div className="container finaltext">
        {isLoading ? (
          <p className="finaltext1">
            Please choose the foreground position you prefer!
            <br /> or <br />
            <a
              className="clickhere"
              target="_blank"
              onClick={() => setPositionDownload("ALL")}
            >
              Click Here
            </a>{" "}
            to download all 3
          </p>
        ) : (
          <p className="finaltext1">
            Please choose from following images according to the position you
            prefer!
            <br /> or Click Here to download all 3
          </p>
        )}
        <div className="row row-45">
          <div className="col-md-4">
            <div>
              <div className="thumbnail">
                {isLoading ? (
                  <a
                    className="downimglink"
                    target="_blank"
                    onClick={() => setPositionDownload("LEFT")}
                  >
                    <div className="overlaycontainer">
                      {colorShow ? (
                        <img
                          className="downimg"
                          src={
                            process.env.PUBLIC_URL + "/images/alphaChannel.png"
                          }
                          style={{
                            backgroundColor: colorBg,
                          }}
                        />
                      ) : (
                        <img
                          className="downimg"
                          src={
                            selectedImageMain
                              ? selectedImageMain
                              : setSelectedImageMain(
                                  "/images/backdrops/themeBG.png"
                                )
                          }
                          alt="icon"
                        />
                      )}
                      {/* Left bg removed img */}
                      <div class="overlayimg">
                        <img
                          className="thumboverlay"
                          src={
                            "https://storage.googleapis.com/analysisimagebucket/" +
                            newUid[0].substring(0, newUid[0].length - 4) +
                            "UpscaledNoAlphal.png"
                          }
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src =
                              "https://storage.googleapis.com/analysisimagebucket/" +
                              newUid[0].substring(0, newUid[0].length - 4) +
                              "UpscaledNoAlphal.png";
                          }}
                          onLoad={() => setCount(count + 1)}
                        />
                      </div>
                      <div class="overlay">
                        <div class="text">CLICK TO DOWNLOAD</div>
                      </div>
                      <div class="overlaybtn">
                        <img
                          className="thumbbtn"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/direct-download1.png"
                          }
                        />
                      </div>
                    </div>
                  </a>
                ) : (
                  <a className="downimglink" target="_blank">
                    <div className="overlaycontainer">
                      {colorShow ? (
                        <img
                          className="downimg"
                          src={
                            process.env.PUBLIC_URL + "/images/alphaChannel.png"
                          }
                          style={{
                            backgroundColor: colorBg,
                          }}
                        />
                      ) : (
                        <img
                          className="downimg"
                          src={
                            selectedImageMain
                              ? selectedImageMain
                              : setSelectedImageMain(
                                  "/images/backdrops/themeBG.png"
                                )
                          }
                          alt="icon"
                        />
                      )}
                      {/* Left bg removed img */}
                      <div class="overlayimg">
                        <img
                          className="thumboverlay"
                          src={
                            "https://storage.googleapis.com/analysisimagebucket/" +
                            newUid[0].substring(0, newUid[0].length - 4) +
                            "UpscaledNoAlphal.png"
                          }
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src =
                              "https://storage.googleapis.com/analysisimagebucket/" +
                              newUid[0].substring(0, newUid[0].length - 4) +
                              "UpscaledNoAlphal.png";
                          }}
                          onLoad={() => setCount(count + 1)}
                        />
                      </div>
                      {pos == "LEFT" || pos == "ALL" ? (
                        <div class="overlayLoading">
                          <div class="text">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/animations/spinner1.svg"
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div class="overlay">
                          <div class="text"></div>
                        </div>
                      )}
                    </div>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <div className="thumbnail">
                {isLoading ? (
                  <a
                    className="downimglink"
                    target="_blank"
                    onClick={() => setPositionDownload("CENTER")}
                  >
                    <div className="overlaycontainer">
                      {colorShow ? (
                        <img
                          className="downimg"
                          src={
                            process.env.PUBLIC_URL + "/images/alphaChannel.png"
                          }
                          style={{
                            backgroundColor: colorBg,
                          }}
                        />
                      ) : (
                        <img
                          className="downimg"
                          src={
                            selectedImageMain
                              ? selectedImageMain
                              : setSelectedImageMain(
                                  "/images/backdrops/themeBG.png"
                                )
                          }
                          alt="icon"
                        />
                      )}
                      {/* center bg removed img */}
                      <div class="overlayimg">
                        <img
                          className="thumboverlay"
                          src={
                            "https://storage.googleapis.com/analysisimagebucket/" +
                            newUid[0].substring(0, newUid[0].length - 4) +
                            "UpscaledNoAlphac.png"
                          }
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src =
                              "https://storage.googleapis.com/analysisimagebucket/" +
                              newUid[0].substring(0, newUid[0].length - 4) +
                              "UpscaledNoAlphac.png";
                          }}
                          onLoad={() => setCount(count + 1)}
                        />
                      </div>
                      <div class="overlay">
                        <div class="text">CLICK TO DOWNLOAD</div>
                      </div>
                      <div class="overlaybtn">
                        <img
                          className="thumbbtn"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/direct-download1.png"
                          }
                        />
                      </div>
                    </div>
                  </a>
                ) : (
                  <a className="downimglink">
                    <div className="overlaycontainer">
                      {colorShow ? (
                        <img
                          className="downimg"
                          src={
                            process.env.PUBLIC_URL + "/images/alphaChannel.png"
                          }
                          style={{
                            backgroundColor: colorBg,
                          }}
                        />
                      ) : (
                        <img
                          className="downimg"
                          src={
                            selectedImageMain
                              ? selectedImageMain
                              : setSelectedImageMain(
                                  "/images/backdrops/themeBG.png"
                                )
                          }
                          alt="icon"
                        />
                      )}
                      {/* center bg removed img */}
                      <div class="overlayimg">
                        <img
                          className="thumboverlay"
                          src={
                            "https://storage.googleapis.com/analysisimagebucket/" +
                            newUid[0].substring(0, newUid[0].length - 4) +
                            "UpscaledNoAlphac.png"
                          }
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src =
                              "https://storage.googleapis.com/analysisimagebucket/" +
                              newUid[0].substring(0, newUid[0].length - 4) +
                              "UpscaledNoAlphac.png";
                          }}
                          onLoad={() => setCount(count + 1)}
                        />
                      </div>
                      {pos == "CENTER" || pos == "ALL" ? (
                        <div class="overlayLoading">
                          <div class="text">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/animations/spinner1.svg"
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div class="overlay">
                          <div class="text"></div>
                        </div>
                      )}
                    </div>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <div className="thumbnail">
                {isLoading ? (
                  <a
                    className="downimglink"
                    target="_blank"
                    onClick={() => setPositionDownload("RIGHT")}
                  >
                    <div className="overlaycontainer">
                      {colorShow ? (
                        <img
                          className="downimg"
                          src={
                            process.env.PUBLIC_URL + "/images/alphaChannel.png"
                          }
                          style={{
                            backgroundColor: colorBg,
                          }}
                        />
                      ) : (
                        <img
                          className="downimg"
                          src={
                            selectedImageMain
                              ? selectedImageMain
                              : setSelectedImageMain(
                                  "/images/backdrops/themeBG.png"
                                )
                          }
                          alt="icon"
                        />
                      )}
                      {/* Right bg removed img */}
                      <div class="overlayimg">
                        <img
                          className="thumboverlay"
                          src={
                            "https://storage.googleapis.com/analysisimagebucket/" +
                            newUid[0].substring(0, newUid[0].length - 4) +
                            "UpscaledNoAlphar.png"
                          }
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src =
                              "https://storage.googleapis.com/analysisimagebucket/" +
                              newUid[0].substring(0, newUid[0].length - 4) +
                              "UpscaledNoAlphar.png";
                          }}
                          onLoad={() => setCount(count + 1)}
                        />
                      </div>
                      <div class="overlay">
                        <div class="text">CLICK TO DOWNLOAD</div>
                      </div>
                      <div class="overlaybtn">
                        <img
                          className="thumbbtn"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/direct-download1.png"
                          }
                        />
                      </div>
                    </div>
                  </a>
                ) : (
                  <a className="downimglink" target="_blank">
                    <div className="overlaycontainer">
                      {colorShow ? (
                        <img
                          className="downimg"
                          src={
                            process.env.PUBLIC_URL + "/images/alphaChannel.png"
                          }
                          style={{
                            backgroundColor: colorBg,
                          }}
                        />
                      ) : (
                        <img
                          className="downimg"
                          src={
                            selectedImageMain
                              ? selectedImageMain
                              : setSelectedImageMain(
                                  "/images/backdrops/themeBG.png"
                                )
                          }
                          alt="icon"
                        />
                      )}
                      {/* Right bg removed img */}
                      <div class="overlayimg">
                        <img
                          className="thumboverlay"
                          src={
                            "https://storage.googleapis.com/analysisimagebucket/" +
                            newUid[0].substring(0, newUid[0].length - 4) +
                            "UpscaledNoAlphar.png"
                          }
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src =
                              "https://storage.googleapis.com/analysisimagebucket/" +
                              newUid[0].substring(0, newUid[0].length - 4) +
                              "UpscaledNoAlphar.png";
                          }}
                          onLoad={() => setCount(count + 1)}
                        />
                      </div>
                      {pos == "RIGHT" || pos == "ALL" ? (
                        <div class="overlayLoading">
                          <div class="text">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/animations/spinner1.svg"
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div class="overlay">
                          <div class="text"></div>
                        </div>
                      )}
                    </div>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeThumbnails;
