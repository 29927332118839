import React from "react";
import CounterUp from "./CounterUp";
import Tilty from "react-tilty";

const CounterUpTwo = ({ prop }) => {
  return (
    <div className="section section-padding expert-counterup-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="section-heading heading-left">
              <div className="col-lg-6">
                <Tilty perspective={2000} reset={true}>
                  <img
                    className="thumbnail-preview"
                    src={
                      "https://storage.googleapis.com/analysisimagebucket/" +
                      prop["fileName"]
                    }
                    alt="Input Thumbnail"
                  />
                </Tilty>
              </div>
              <span className="subtitle">Final Score...</span>
              <h2>{prop["data"][4]}%</h2>
              <p className="mb--50">
                {prop["data"][4] > 80 ? (
                  <p>
                    <b>Excellent -</b> Thumbnails in this category are very
                    eye-catching and engaging. They are likely to attract a high
                    click-through rate and generate a lot of views for the
                    video.
                  </p>
                ) : prop["data"][4] > 60 ? (
                  <p>
                    <b>Great -</b> Thumbnails in this category are quite
                    eye-catching and engaging. They should generate a solid
                    click-through rate and a good number of views.
                  </p>
                ) : prop["data"][4] > 50 ? (
                  <p>
                    <b>Good -</b> Thumbnails in this category are decent, but
                    not quite as eye-catching or engaging as those in the higher
                    categories. They may generate a moderate click-through rate
                    and a moderate number of views.
                  </p>
                ) : prop["data"][4] > 40 ? (
                  <p>
                    <b>Average -</b> Thumbnails in this category are just okay.
                    They are not particularly eye-catching or engaging, but
                    they're not terrible either. They may generate a lower
                    click-through rate and fewer views than those in the higher
                    categories.
                  </p>
                ) : prop["data"][4] > 20 ? (
                  <p>
                    <b>Not Engaging -</b> Thumbnails in this category are not
                    likely to capture viewers' attention or generate much
                    interest in the video.
                  </p>
                ) : (
                  <p>
                    <b>Poor Thumbnail -</b> Thumbnails in this category are very
                    unappealing and unlikely to generate any interest in the
                    video.
                  </p>
                )}
              </p>
            </div>
          </div>
          <div className="col-lg-6 offset-xl-1">
            <div className="row">
              <CounterUp
                colSize="col-sm-6"
                layoutStyle="counterup-style-2"
                evenTopMargin=""
                prop={prop["data"]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterUpTwo;
