import React, { useState, useRef, useEffect } from "react";
import {
  CloudDownloadOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { BlockPicker, SwatchesPicker } from "react-color";
// import { ColorPicker, ColorBox } from "material-ui-color";
import Dialog from "@mui/material/Dialog";
import "react-color-palette/lib/css/styles.css";
import TabCusGen from "./TabCusGen";
import SendIcon from "@mui/icons-material/Send";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import saveAs from "file-saver";
import { FaDownload, FaFileDownload } from "react-icons/fa";
import { supabase } from "../../utils/supabaseClient";
import axios from "axios";

let theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "Poppins"].join(","),
  },
  palette: {
    primary: {
      main: "#00adb4",
    },
    secondary: {
      main: "#00adb4",
    },
  },
});

function ImagePreviewBox({
  token,
  uid,
  setSelectedImageMain,
  setCount,
  count,
  colorBg,
  setColorBg,
  colorShow,
  setColorShow,
  credits,
  setCredits,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [promptIsLoading, setPromptIsLoading] = useState(false);
  const [select, setSelect] = useState(true);
  const [generate, setGenerate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [bgGenCount, setBgGenCount] = useState(0);
  const [genImageName, setGenImageName] = useState("");

  function download(urlArr) {
    (async () => {
      let name = genImageName;
      let blob = await fetch(urlArr).then((r) => r.blob());
      saveAs(blob, name);
    })();
  }

  function handleClickOpenDialog(event) {
    event.preventDefault();
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  const props = {
    name: "file",
    method: "POST",
    crossOrigin: "anonymous",
    multiple: false,
    action: "https://www.impressions.one/bgUploadsTG",

    onChange(info) {
      setIsLoading(false);
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setSelectedImage(
          "https://storage.googleapis.com/analysisimagebucket/" +
            info.file.response
        );
        setSelectedImageMain(
          "https://storage.googleapis.com/analysisimagebucket/" +
            info.file.response
        );

        message.success(
          `file uploaded succesfully, changes will show up shortly`
        );

        setColorShow(false);
        setIsLoading(true);
        setBgGenCount(0);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const [selectedImage, setSelectedImage] = useState();

  const [Image, setImage] = useState(
    "https://storage.googleapis.com/analysisimagebucket/" +
      uid.substring(0, uid.length - 4) +
      "UpscaledNoAlphac.png"
  );

  function handleImageClick(imageUrl) {
    setSelectedImage(imageUrl);
    setSelectedImageMain(imageUrl);
    setColorShow(false);
    setBgGenCount(0);
  }

  function handleChangeComplete(color) {
    setColorBg(color.hex);
    setColorShow(true);
    setBgGenCount(0);
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const imageList = [
    "/images/backdrops/themeBG.png",
    "/images/backdrops/bg1.png",
    "/images/backdrops/bg2.png",
    "/images/backdrops/bg3.png",
    "/images/backdrops/bg4.png",
    "/images/backdrops/bg5.png",
    "/images/backdrops/bg6.png",
    "/images/backdrops/bg7.png",
    "/images/backdrops/bg8.png",
    "/images/backdrops/bg9.png",
    "/images/backdrops/bg10.png",
    "/images/backdrops/bg11.png",
    "/images/backdrops/bg13.png",
    "/images/backdrops/bg14.png",
    "/images/backdrops/bg15.jpg",
    "/images/backdrops/bg16.jpg",
  ];

  function handleBgUpload() {
    document.getElementById("file-upload").click();
  }

  const fallbackSrc = Image;

  const [prompt, setPrompt] = useState("");
  const textareaRef = useRef(null);

  const handleChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleSubmit = () => {
    setPromptIsLoading(true);
    if (prompt.trim() !== "") {
      sendPrompt();
      setPrompt("");
      handleCloseDialog();
    }
  };
  //fetch and subtract credits left every time the prop is called
  const [creditsLeft, setCreditsLeft] = useState(credits);
  const creditsCost = 2;

  const deductCredits = () => {
    // console.log("check how many credits");
    setCreditsLeft(creditsLeft - creditsCost);
    // console.log("subtract 1 credit");
  };
  const notEnoughCreditsAlert = () => {
    message.warning("Not enough credits. Go to your profile page to get more.");
  };

  async function sendPrompt() {
    fetch("/bgGenTG", {
      method: "POST",
      body: JSON.stringify("A photograph of " + prompt),
    })
      .then((response) => response.text()) // Parse response body as JSON
      .then((data) => {
        // Use the response data here
        // console.log(data.length);
        if (data.length === 265) {
          message.error("Inappropriate/ Invalid Prompt");
          setPromptIsLoading(false);
        } else {
          const img = new window.Image();
          img.src =
            "https://storage.googleapis.com/analysisimagebucket/" + data;

          img.onload = () => {
            message.success(
              "Image has been generated, changes will show up shortly"
            );
            deductCredits();
            fetchCredits(token);
            setBgGenCount(bgGenCount + 1);
            setGenImageName(data);
            setSelectedImage(
              "https://storage.googleapis.com/analysisimagebucket/" + data
            );
            setSelectedImageMain(
              "https://storage.googleapis.com/analysisimagebucket/" + data
            );
            setColorShow(false);
          };
          img.onerror = () => {
            message.error("An error occured! Please try again");
          };
          setPromptIsLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err.message);
      });
  }

  async function updateCredits(token) {
    try {
      const { error } = await supabase
        .from("users")
        .update({ credits: creditsLeft })
        .eq("email", token.user.email);
      if (error) throw error;
    } catch (error) {
      message.error(error.message + "");
    }
  }

  async function fetchCredits(token) {
    try {
      const { data, error } = await supabase
        .from("users")
        .select("credits")
        .eq("email", token.user.email);
      if (error) throw error;
      if (data) {
        setCredits(data[0].credits);
        // console.log(data[0].credits);
      }
    } catch (error) {
      message.error(error.message + "");
    }
  }

  useEffect(() => {
    updateCredits(token);
    fetchCredits(token);
  }, [creditsLeft]);

  return (
    <>
      <div className="image-preview-box-container">
        <div className="imgcontainer">
          <img
            className="selected-image layer2"
            src={Image}
            onError={(e) => {
              e.target.onError = null;
              e.target.src = fallbackSrc;
              console.clear();
            }}
            alt="Image"
            onLoad={() => setCount(count + 1)}
          />

          {colorShow ? (
            <img
              className="selected-image layer1"
              src={process.env.PUBLIC_URL + "/images/alphaChannel.png"}
              style={{
                backgroundColor: colorBg,
              }}
            />
          ) : (
            <img
              id="imgTest"
              className="selected-image layer1"
              src={
                selectedImage
                  ? selectedImage
                  : setSelectedImage("/images/backdrops/themeBG.png")
              }
              alt="Selected Image"
              onLoad={() => setCount(count + 1)}
            />
          )}
        </div>
        <div className="outer-slider">
          <div className="tabcusgen top">
            <TabCusGen setSelect={setSelect} setGenerate={setGenerate} />
            <ul className="image-list">
              {generate ? (
                creditsLeft >= creditsCost ? (
                  <form
                    className="chat-typing-box"
                    onSubmit={handleClickOpenDialog}
                    target="/"
                  >
                    {promptIsLoading ? (
                      <div class="loader-bg-gen"></div>
                    ) : (
                      <>
                        <textarea
                          required={prompt != "" || bgGenCount == 0}
                          ref={textareaRef}
                          placeholder="Type your prompt here..."
                          value={prompt}
                          onChange={handleChange}
                        />
                        {prompt == "" && bgGenCount > 0 ? (
                          <>
                            <button
                              type="button"
                              onClick={() => download(selectedImage)}
                            >
                              <FaDownload /> Generated BG{" "}
                            </button>
                          </>
                        ) : (
                          <button type="submit">
                            <SendIcon />{" "}
                          </button>
                        )}
                      </>
                    )}
                  </form>
                ) : (
                  <div className="chat-typing-box">
                    <>
                      <textarea
                        required={prompt != "" || bgGenCount == 0}
                        ref={textareaRef}
                        placeholder="Out of credits..."
                        value={prompt}
                        disabled
                      />
                      {prompt == "" && bgGenCount > 0 ? (
                        <>
                          <button
                            type="button"
                            onClick={() => download(selectedImage)}
                          >
                            <FaDownload /> Generated BG{" "}
                          </button>
                        </>
                      ) : (
                        <button type="submit" onClick={notEnoughCreditsAlert}>
                          <SendIcon />{" "}
                        </button>
                      )}
                    </>
                  </div>
                )
              ) : (
                <>
                  {isLoading ? (
                    <li onClick={() => handleBgUpload()}>
                      <div className="outer-slider">
                        <div className="top">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/backdrops/addbg.png"
                            }
                            alt={`addimg`}
                            onLoad={() => setCount(count + 1)}
                          />
                        </div>
                        <div className="below">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/animations/spinnerHome.svg"
                            }
                            alt={`addimg`}
                          />
                        </div>
                      </div>

                      <Upload
                        id="file-upload"
                        className="bguploadbtn"
                        {...props}
                        maxCount={1}
                        accept=".png,.jpg,.jpeg,"
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </li>
                  ) : (
                    <li>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/animations/spinnerHome.svg"
                        }
                        alt={`addimg`}
                      />

                      <Upload
                        id="file-upload"
                        className="bguploadbtn"
                        {...props}
                        maxCount={1}
                        accept=".png,.jpg,.jpeg,"
                      ></Upload>
                    </li>
                  )}
                  <li onClick={handleClickOpen}>
                    <div className="outer-slider">
                      <div className="top">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/backdrops/solid-colors.png"
                          }
                          alt={`addimg`}
                          onLoad={() => setCount(count + 1)}
                        />
                      </div>
                      <div className="below">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/animations/spinnerHome.svg"
                          }
                          alt={`addimg`}
                        />
                      </div>
                    </div>
                  </li>
                  {imageList.map((imageUrl, index) => (
                    <li key={index} onClick={() => handleImageClick(imageUrl)}>
                      <div className="outer-slider">
                        <div className="top">
                          <img
                            src={process.env.PUBLIC_URL + imageUrl}
                            alt={`Image ${index}`}
                            onLoad={() => setCount(count + 1)}
                          />
                        </div>
                        <div className="below">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/animations/spinnerHome.svg"
                            }
                            alt={`addimg`}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
          <div className="tabcusgen ul-placeholder below ">
            <ul className="image-list">
              <form className="chat-typing-box">
                <textarea disabled />
              </form>
            </ul>
          </div>
        </div>
        <ThemeProvider theme={theme}>
          <Dialog
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
                width: "2000px",
              },
            }}
            open={openDialog}
            onClose={handleCloseDialog}
          >
            <div class="signup-form contact-form-box shadow-box dialog prompt">
              {/* {loadingDialog ? (

                <ThemeProvider theme={theme}>
                  <Box sx={{ width: '100%', paddingBottom: '15px' }}>
                    <LinearProgress sx={{ height: '17px', borderRadius: '8px' }} variant="buffer" value={progress} valueBuffer={buffer} />
                  </Box>
                </ThemeProvider>

              ) : ( */}

              <div class="container signin-container">
                <div class="header">
                  <h1 className="signin-title">
                    Are you sure you want to continue?
                  </h1>
                  <p>
                    This action cannot be undone and will cost you 2 credits
                  </p>
                </div>

                <input
                  type="button"
                  readonly
                  onClick={handleCloseDialog}
                  value="Cancel"
                  class="dialogButton cancelbtn"
                />
                <input
                  type="submit"
                  value="Confirm"
                  class="dialogButton confirmbtn"
                  onClick={handleSubmit}
                />
              </div>
              {/* )} */}
            </div>
          </Dialog>
        </ThemeProvider>

        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              borderRadius: "20px",
              width: "400px",
            },
          }}
          open={open}
          onClose={handleClose}
        >
          <SwatchesPicker
            width="auto"
            onChangeComplete={handleChangeComplete}
          />
          <BlockPicker
            width="auto"
            color={colorBg}
            onChangeComplete={handleChangeComplete}
          />
        </Dialog>
      </div>
    </>
  );
}

export default ImagePreviewBox;
