import React from "react";
import FooterOne from "../common/footer/FooterOne";
import SEO from "../common/SEO";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SectionTitle from "../elements/section-title/SectionTitle";
import PricingOne from "../component/pricing/PricingOne";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderLogggedIn from "../common/header/HeaderLoggedIn";

const PricingTable = ({ credits, setCredits, token, setToken }) => {
  return (
    <>
      <SEO title="Pricing" />
      <ColorSwitcher />
      <main className="main-wrapper">
        {token ? (
          <HeaderLogggedIn
            email={token.user.email}
            setToken={setToken}
            credits={credits}
            setCredits={setCredits}
          />
        ) : (
          <HeaderTwo />
        )}{" "}
        <BreadCrumbOne page="Pricing" />
        <div className="section section-padding">
          <div className="container">
            <SectionTitle
              subtitle="Pricing Plan"
              title="Find the Right Plan."
              description="Flexible pricing options for freelancers <br> and professionals."
              textAlignment="mb-0"
              textColor=""
            />
            <PricingOne token={token} />
          </div>
          <ul className="shape-group-3 list-unstyled">
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}
                alt="shape"
              />
            </li>
          </ul>
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};
export default PricingTable;
