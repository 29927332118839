import { useState, React, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTwo from "../../common/header/HeaderTwo";
import Loadinganim from "../../component/Loadinganim";
import { useLocation } from "react-router-dom";
import ComparisonSliderUpscale from "../../component/project/ComparisonSliderUpscale";
import { supabase } from "../../utils/supabaseClient";
import { message } from "antd";
import HeaderLogggedIn from "../../common/header/HeaderLoggedIn";

const UpscaleFinal = ({ token, setToken, credits, setCredits }) => {
  const [upscaleData, setUpscaleData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const uid = location.state;
  const [count, setCount] = useState(0);

  // const handleLoadingFromChild = (state) => {
  //   setIsLoading(state);
  // };

  // if (uid == null) {
  //   navigate("/");
  // } else if (Object.keys(upscaleData).length == 0) {
  //   navigate("/");
  // }

  // useEffect(async () => {
  //   await fetch("/generatedUpscale")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       {
  //         for (let i = 0; i < data.generatedUpscale.length; i++) {
  //           data.generatedUpscale[i][1] == uid &&
  //             setUpscaleData(data.generatedUpscale[i]);

  //           fetch("/deleteUpscale", {
  //             method: "POST",
  //             // We convert the React state to JSON and send it as the POST body
  //             body: JSON.stringify(uid),
  //           }).then(function (response) {
  //             return response.json();
  //           });
  //         }
  //       }

  //       setIsLoading(false);
  //       // console.log(upscaleData);
  //     })
  //     .catch((error) => {
  //       // console.log("Error");
  //     });
  // }, []);
  // console.log(upscaleData);
  // console.log(Object.keys(upscaleData).length);

  async function getSessionAuth() {
    // e.preventDefault()

    try {
      // setIsLoading(true);

      const { data, error } = await supabase.auth.getSession();

      if (error) throw error;
      if (data.session) {
        // console.log(data.session);
        setToken(data.session);

        // navigate('/auth-test')
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      message.error(error.message + "");
      // console.log(error);
    }
    // finally {
    //   setIsLoading(false);
    // }
  }
  useEffect(() => {
    getSessionAuth();
  }, []);
  if (credits != null) {
    if (uid == null) {
      return <Navigate to="/upscale" />;
    } else {
      if (count < 2) {
        return (
          <>
            <SEO title="Loading" />
            <ColorSwitcher />
            <main className="main-wrapper loadingpg">
              {token ? (
                <HeaderLogggedIn
                  email={token.user.email}
                  setToken={setToken}
                  credits={credits}
                  setCredits={setCredits}
                />
              ) : (
                <HeaderTwo />
              )}{" "}
              <Loadinganim />
              <div className="hide-slider">
                <ComparisonSliderUpscale
                  prop={uid}
                  count={count}
                  setCount={setCount}
                />
              </div>
              <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                    alt="Line"
                  />
                </li>
              </ul>
            </main>
          </>
        );
      } else {
        return (
          <>
            <SEO title="Upscale Image" />
            <ColorSwitcher />
            <main className="main-wrapper">
              {token ? (
                <HeaderLogggedIn
                  email={token.user.email}
                  setToken={setToken}
                  credits={credits}
                  setCredits={setCredits}
                />
              ) : (
                <HeaderTwo />
              )}{" "}
              <div className="banner banner-style-3 thumbgenface">
                <ComparisonSliderUpscale
                  prop={uid}
                  count={count}
                  setCount={setCount}
                />

                <ul className="list-unstyled shape-group-20">
                  <li className="shape shape-1">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-32.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-33.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-14.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-4.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  {/* <li className="shape shape-6">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-30.png"} alt="Bubble" />
                        </li> */}
                  <li className="shape shape-7">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-31.png"
                      }
                      alt="Bubble"
                    />
                  </li>
                  <li className="shape shape-8 marque-images" />
                </ul>
              </div>
              <FooterTwo />
            </main>
          </>
        );
      }
    }
  } else {
    return (
      <>
        <SEO title="Loading" />
        <ColorSwitcher />
        <main className="main-wrapper loadingpg">
          <HeaderTwo />
          <Loadinganim />

          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      </>
    );
  }
};

export default UpscaleFinal;
