import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import { Link } from "react-router-dom";
import HeaderLogggedIn from "../../common/header/HeaderLoggedIn";
import Avatar from "@mui/material/Avatar";
import { supabase } from "../../utils/supabaseClient";
import { message } from "antd";
import Loadinganim from "../../component/Loadinganim";

const Profile = ({ token, setToken, credits, setCredits }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  async function getSessionAuth() {
    try {
      setIsLoading(true);
      const { data, error } = await supabase.auth.getSession();
      if (error) throw error;
      if (data.session) {
        setToken(data.session);
      } else {
        navigate("/sign-in", { state: "profile" });
      }
    } catch (error) {
      message.error(error.message + "");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getSessionAuth();
  }, []);

  if (isLoading) {
    return (
      <>
        <SEO title="Loading" />
        <ColorSwitcher />
        <main className="main-wrapper loadingpg">
          {token ? (
            <HeaderLogggedIn
              email={token.user.email}
              setToken={setToken}
              credits={credits}
              setCredits={setCredits}
            />
          ) : (
            <HeaderTwo />
          )}{" "}
          <Loadinganim />
          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      </>
    );
  } else {
    return (
      <>
        <SEO title="Profile" />
        <ColorSwitcher />
        <main className="main-wrapper">
          {token ? (
            <HeaderLogggedIn
              email={token.user.email}
              setToken={setToken}
              credits={credits}
              setCredits={setCredits}
            />
          ) : (
            <HeaderTwo />
          )}
          <div className="row row-signin">
            <div className="profile-body coming-soon-area onepage-screen-area">
              <div class="profile-box signup-form contact-form-box shadow-box">
                <div class="container signin-container">
                  <div class="header">
                    <div className="profile-avatar">
                      <Avatar
                        className="profile-avatar-mui"
                        sx={{
                          bgcolor: "#00adb4",
                          width: 120,
                          height: 120,
                          boxShadow:
                            "0 0 30px 0 rgba(69, 196, 234, 0.15), 0 6px 12px 0 rgba(23, 210, 224, 0.15)",
                          fontSize: "50px",
                        }}
                      >
                        {token ? token.user.email[0].toUpperCase() : null}
                      </Avatar>
                    </div>
                    <h1 className="profile-email signin-title">
                      {token
                        ? token.user.email.length > 28
                          ? token.user.email.slice(0, 28) + "..."
                          : token.user.email
                        : null}
                    </h1>
                    <div className="profile-credit">
                      <h3 className="profile-credit-display">{credits}</h3>
                      <img
                        className="profile-credit-icon"
                        src={process.env.PUBLIC_URL + "/images/token.png"}
                      ></img>
                    </div>
                    <p className="profile-text">
                      Utilize these credits for accessing a range of features
                      across impressions.one
                    </p>
                  </div>

                  <p className="signin-text">
                    Need more credits?{" "}
                    {/* <Link
                      style={{
                        textDecoration: "none",
                        color: "#00adb4",
                      }}
                      className="signin-link"
                      to={"/contact"}
                    >
                      Contact Us
                    </Link> */}
                    {/* <stripe-buy-button
                      buy-button-id="buy_btn_1NpvkQEQPDE8WyBqtCx7K7mL"
                      publishable-key="pk_live_51NpvJAEQPDE8WyBqw6ieS8XTxfwphZf0ykkhC7Ivyes6GcWguExjoQjEMViReXmzmLIsajw0uGBnLRpL4dDvmO1e00ew5d9oIU"
                    ></stripe-buy-button> */}
                  </p>
                  {/* <div className="payment-buuton-div"> */}
                  {/* <stripe-buy-button
                      className="sripe-buy-button"
                      buy-button-id="buy_btn_1NpvkQEQPDE8WyBqtCx7K7mL"
                      publishable-key="pk_live_51NpvJAEQPDE8WyBqw6ieS8XTxfwphZf0ykkhC7Ivyes6GcWguExjoQjEMViReXmzmLIsajw0uGBnLRpL4dDvmO1e00ew5d9oIU"
                    ></stripe-buy-button> */}
                  <Link
                    to={process.env.PUBLIC_URL + "/pricing"}
                    className="axil-btn btn-fill-primary get-started-btn get-credits-btn"
                  >
                    Get Credits
                  </Link>

                  {/* </div> */}
                </div>
              </div>

              <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                    alt="Line"
                  />
                </li>
              </ul>
            </div>
          </div>

          <FooterOne />
        </main>
      </>
    );
  }
};
export default Profile;
