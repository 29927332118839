import React, { useEffect, useState, useCallback } from "react";
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import { Link } from "react-router-dom";
import { supabase } from "../../utils/supabaseClient";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { message } from "antd";
import { createTheme } from "@mui/material/styles";
import Loadinganim from "../../component/Loadinganim";
import FooterWithoutSocials from "../../common/footer/FooterWithoutSocials";

let theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "Poppins"].join(","),
  },
  palette: {
    primary: {
      main: "#00adb4",
    },
    secondary: {
      main: "#00adb4",
    },
  },
});

const ForgotPw = ({ token }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    newpwd: "",
    confirmnewpwd: "",
  });

  //   console.log(formData);

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (formData.newpwd == formData.confirmnewpwd) {
      if (formData.newpwd.length >= 6) {
        try {
          setLoading(true);
          const { data, error } = await supabase.auth.updateUser({
            password: formData.newpwd,
          });

          if (error) throw error;
          message.success("Password updated successfully!");
          navigate("/sign-in");
        } catch (error) {
          if (error.message == "Auth session missing!") {
            message.error("This password recovery link is expired!");
          } else {
            message.error(error.message + "");
          }
        } finally {
          setLoading(false);
        }
      } else {
        message.error("Password must be at least 6 characters");
      }
    } else {
      message.error("Password and Confirm Password does not match");
    }
  }

  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const lockScroll = useCallback(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollBarCompensation}px`;
  }, []);
  const unlockScroll = useCallback(() => {
    document.body.style.overflow = "";
  }, []);

  useEffect(() => {
    if (loading) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [loading]);

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <>
      <SEO title="Reset Password" />
      <ColorSwitcher />
      {loading ? (
        <main className="main-wrapper loadingpg">
          <HeaderTwo />
          <Loadinganim />
          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
          </ul>
        </main>
      ) : (
        <main className="main-wrapper">
          <HeaderTwo />
          <div className="row row-signin">
            <div className="coming-soon-area onepage-screen-area">
              <div class="signup-form contact-form-box shadow-box">
                <div class="container signin-container">
                  <div class="header">
                    <h1 className="signin-title">Reset Password</h1>
                    <p>Enter a new password</p>
                  </div>
                  <form onSubmit={handleSubmit} classname="axil-contact-form">
                    <div class="input">
                      <i>
                        <FontAwesomeIcon icon={faLock} />
                      </i>
                      <input
                        required
                        type={passwordShown1 ? "text" : "password"}
                        placeholder="New Password"
                        name="newpwd"
                        onChange={handleChange}
                      />
                      {passwordShown1 ? (
                        <i className="eye">
                          <FontAwesomeIcon
                            onClick={togglePassword1}
                            icon={faEye}
                          />
                        </i>
                      ) : (
                        <i className="eye">
                          <FontAwesomeIcon
                            onClick={togglePassword1}
                            icon={faEyeSlash}
                          />
                        </i>
                      )}
                    </div>
                    <div class="input">
                      <i>
                        <FontAwesomeIcon icon={faLock} />
                      </i>
                      <input
                        required
                        type={passwordShown2 ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="confirmnewpwd"
                        onChange={handleChange}
                      />
                      {passwordShown2 ? (
                        <i className="eye">
                          <FontAwesomeIcon
                            onClick={togglePassword2}
                            icon={faEye}
                          />
                        </i>
                      ) : (
                        <i className="eye">
                          <FontAwesomeIcon
                            onClick={togglePassword2}
                            icon={faEyeSlash}
                          />
                        </i>
                      )}
                    </div>
                    <input
                      class="signup-btn"
                      type="submit"
                      value="RESET PASSWORD"
                    />
                  </form>

                  <p className="signin-text">
                    Don't have an account?{" "}
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#00adb4",
                      }}
                      classname="signin-link"
                      to={"/sign-up"}
                    >
                      Sign Up
                    </Link>
                  </p>
                </div>
              </div>

              <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                    alt="Line"
                  />
                </li>
              </ul>
            </div>
          </div>

          <FooterWithoutSocials parentClass="" />
        </main>
      )}
    </>
  );
};

export default ForgotPw;
