import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

const VideoTwo = () => {
  const [toggler, setToggler] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setToggler(!toggler);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="section section-padding-equal">
        <div className="container">
          {/* <SectionTitle 
                        subtitle="Experts in field"
                        title="Design startup <br> movement"
                        description=""
                        textAlignment=""
                        textColor=""
                    /> */}
          <div className="about-expert">
            {/* <div className="thumbnail"> */}
            {/* <img
                src={
                  process.env.PUBLIC_URL +
                  "https://siteimages.simplified.com/blog/The-Most-Attractive-Youtube-Thumbnail.png?auto=format&ixlib=php-3.3.1"
                }
                alt="Thumbnail"
              /> */}
            <div class="videoWrapper">
              <iframe
                width="560"
                height="349"
                src="https://www.youtube.com/embed/HL2DtIiPG_U"
                allowfullscreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
            {/* <div className="popup-video">
                <button
                  className="play-btn"
                  onClick={() => setToggler(!toggler)}
                >
                  <FaPlay />
                </button>
              </div> */}
            {/* </div> */}
          </div>
        </div>
        <ul className="shape-group-16 list-unstyled">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
              alt="circle"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
              alt="Line"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
              alt="Line"
            />
          </li>
        </ul>
      </div>
      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/watch?v=1iIZeIy7TqM"]}
      />
    </>
  );
};

export default VideoTwo;
