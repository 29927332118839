import React from "react";
import { Link } from "react-router-dom";
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage,
} from "react-compare-slider";
import saveAs from "file-saver";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ComparisonSliderBGR = ({ style, prop, count, setCount }) => {
  const checkMobile = useMediaQuery("(max-width:991px)");

  function download(urlArr) {
    (async () => {
      let name = prop[1];
      let blob = await fetch(urlArr).then((r) => r.blob());
      saveAs(blob, name);
    })();
  }

  return (
    <div className="section section-padding pb--80 pb_lg--40 pb_md--20 pb_sm--0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="section-heading heading-middle">
              <h4 className="title">Background removal successful!</h4>
              <p>
                Witness the transformation in real-time! Slide back and forth to
                compare the original image with the <b>Background-Free</b>{" "}
                version.
              </p>
            </div>
            <div className="about-expert">
              <div className="thumbnail">
                <ReactCompareSlider
                  className="slider-in"
                  handle={
                    <ReactCompareSliderHandle
                      buttonStyle={{
                        backdropFilter: undefined,
                        background: "white",
                        border: 3,
                        color: "#333",
                      }}
                      linesStyle={{
                        background: "transparent",
                        color: "transparent",
                        boxShadow: 0,
                        border: 0,
                      }}
                    />
                  }
                  itemOne={
                    <ReactCompareSliderImage
                      src={
                        "https://storage.googleapis.com/analysisimagebucket/" +
                        prop[0]
                      }
                      style={{ filter: "", width: "auto", maxHeight: "405px" }}
                      alt="one"
                      onLoad={() => setCount(count + 1)}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={
                        "https://storage.googleapis.com/analysisimagebucket/" +
                        prop[0].substring(0, prop[0].length - 4) +
                        "RemBG.png"
                      }
                      style={{ filter: "", width: "auto", maxHeight: "405px" }}
                      alt="two"
                      onLoad={() => setCount(count + 1)}
                    />
                  }
                  onlyHandleDraggable={checkMobile}
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "auto",
                    ...style,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="more-project-btn col-lg-12 col-md-12 col-sm-12 thumbgenbtn1-upscale">
            <a
              // to={process.env.PUBLIC_URL + ""}
              target="_blank"
              className="axil-btn btn-fill-white thumbgenbtn1lnk"
              onClick={() =>
                download(
                  "https://storage.googleapis.com/analysisimagebucket/" +
                    prop[0].substring(0, prop[0].length - 4) +
                    "RemBG.png"
                )
              }
            >
              Download Image
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonSliderBGR;
