import React from "react";
import { useState } from "react";

const TabList = ({ setAnaRD, setBgrRD, setGenRD, setUpRD }) => {
  const handleGen = () => {
    setGenRD(true);
    setUpRD(false);
    setAnaRD(false);
    setBgrRD(false);
  };

  const handleUp = () => {
    setGenRD(false);
    setUpRD(true);
    setAnaRD(false);
    setBgrRD(false);
  };

  const handleAna = () => {
    setGenRD(false);
    setUpRD(false);
    setAnaRD(true);
    setBgrRD(false);
  };

  const handleBgr = () => {
    setGenRD(false);
    setUpRD(false);
    setAnaRD(false);
    setBgrRD(true);
  };

  return (
    <div className="mainTabList">
      <div class="containerTabList">
        <div class="tabs">
          <input
            type="radio"
            id="radio-1"
            name="tabs"
            onClick={handleGen}
          ></input>
          <label class="tab" for="radio-1">
            Design
          </label>
          <input
            type="radio"
            id="radio-2"
            name="tabs"
            onClick={handleUp}
          ></input>
          <label class="tab" for="radio-2">
            AI Upscale
          </label>

          <input
            type="radio"
            id="radio-3"
            name="tabs"
            onClick={handleBgr}
          ></input>
          <label class="tab" for="radio-3">
            Remove BG
          </label>
          <input
            type="radio"
            id="radio-4"
            name="tabs"
            onClick={handleAna}
          ></input>
          <label class="tab" for="radio-4">
            AI Analyze
          </label>
          <span class="glider"></span>
        </div>
      </div>
    </div>
  );
};

export default TabList;
