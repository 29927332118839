import React from "react";
import FooterOne from "../common/footer/FooterOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderLogggedIn from "../common/header/HeaderLoggedIn";

const TermsOfUse = ({ token, setToken, credits, setCredits }) => {
  return (
    <>
      <SEO title="Terms Of Use" />
      <ColorSwitcher />
      <main className="main-wrapper">
        {token ? (
          <HeaderLogggedIn
            email={token.user.email}
            setToken={setToken}
            credits={credits}
            setCredits={setCredits}
          />
        ) : (
          <HeaderTwo />
        )}
        <BreadCrumbOne title="Terms Of Use" page="Terms Of Use" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <h4>Acceptance of Terms</h4>
                  <p>
                    By using the Service, you represent and warrant that you
                    have read, understood, and agree to be bound by these Terms.
                    If you are using the Service on behalf of an organization,
                    you further represent and warrant that you have the
                    authority to enter into these Terms on behalf of that
                    organization.
                  </p>

                  <h4>Description of Service</h4>
                  <p>
                    Impressions.one provides an innovative web application
                    powered by artificial intelligence (AI) for designing and
                    analyzing YouTube thumbnails. Our platform offers content
                    creators a convenient and centralized tool to enhance the
                    quality of their thumbnails, resulting in improved video
                    impressions and higher engagement rates. We aim to empower
                    users to create visually appealing thumbnails that captivate
                    their audience and drive more views to their videos.
                  </p>

                  <h4>User Conduct</h4>
                  <p>
                    You agree to use the Service only for lawful purposes and in
                    a manner that does not infringe upon the rights of others,
                    restrict or inhibit anyone else's use and enjoyment of the
                    Service, or violate any applicable laws or regulations. You
                    shall not engage in any activity that could harm or disrupt
                    the functioning of the Service.
                  </p>

                  <h4>Intellectual Property</h4>
                  <p>
                    All intellectual property rights related to the Service,
                    including but not limited to the software, algorithms,
                    graphics, designs, and content, are owned by Impressions.one
                    or its licensors. You acknowledge that you do not acquire
                    any ownership rights by using the Service. You may not use,
                    reproduce, distribute, modify, or create derivative works of
                    any content or materials from the Service without our prior
                    written consent.
                  </p>

                  <h4>Privacy Policy</h4>
                  <p>
                    We respect your privacy and are committed to protecting your
                    personal information. Our Privacy Policy governs the
                    collection, use, and disclosure of your personal information
                    and is incorporated by reference into these Terms.
                  </p>

                  <h4>Limitation of Liability</h4>
                  <p>
                    To the maximum extent permitted by law, we shall not be
                    liable for any indirect, incidental, special, consequential,
                    or punitive damages arising out of or relating to your use
                    of the Service, even if we have been advised of the
                    possibility of such damages. In no event shall our total
                    liability to you for all damages.
                  </p>

                  <h4>Indemnification</h4>
                  <p>
                    You agree to indemnify, defend, and hold us harmless from
                    and against any claims, liabilities, damages, losses, and
                    expenses, including without limitation reasonable attorney's
                    fees and costs, arising out of or in any way connected with
                    your use of the Service or your violation of these Terms.
                  </p>

                  <h4>Modifications to Terms</h4>
                  <p>
                    We reserve the right to modify these Terms at any time
                    without prior notice. Any changes to these Terms will be
                    effective immediately upon posting. Your continued use of
                    the Service after the posting of any amended Terms
                    constitutes your agreement to be bound by the modified
                    Terms.
                  </p>

                  <h4>Governing Law</h4>
                  <p>
                    These Terms shall be governed by and construed in accordance
                    with the laws of [jurisdiction]. Any legal action or
                    proceeding arising out of or relating to these Terms or the
                    Service shall be brought exclusively in the courts of
                    [jurisdiction].
                  </p>

                  <h4>Severability</h4>
                  <p>
                    If any provision of these Terms is found to be invalid,
                    illegal, or unenforceable, the remaining provisions shall
                    continue in full force and effect.
                  </p>

                  <h4>Entire Agreement</h4>
                  <p>
                    These Terms constitute the entire agreement between you and
                    us regarding your use of the Service and supersede all prior
                    or contemporaneous agreements, communications, and
                    proposals, whether oral or written, between you and us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TermsOfUse;
