import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { supabase } from "../../utils/supabaseClient";
import Avatar from "@mui/material/Avatar";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { message } from "antd";
import { Popover } from "antd";
import { useMemo } from "react";
const text = <span>Credits Left</span>;
const content = (
  <div>
    {/* <p className="signin-text">Available Credit Balance</p> */}
    <p className="signin-text">
      Need more credits?{" "}
      <Link
        style={{
          textDecoration: "none",
          color: "#00adb4",
        }}
        className="signin-link"
        to={"/pricing"}
      >
        Get Credits
      </Link>
    </p>
  </div>
);

const LoggedInNav = ({ email, setToken, credits, setCredits }) => {
  const [showArrow, setShowArrow] = useState(true);
  const [arrowAtCenter, setArrowAtCenter] = useState(false);
  const mergedArrow = useMemo(() => {
    if (arrowAtCenter)
      return {
        pointAtCenter: true,
      };
    return showArrow;
  }, [showArrow, arrowAtCenter]);
  const user = email.substring(0, email.indexOf("@"));
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  function handleLogout(e) {
    e.preventDefault();
    sessionStorage.removeItem("token");
    signout();
  }

  async function fetchCredits(email) {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("users")
        .select("credits")
        .eq("email", email);
      if (error) throw error;
      if (data) {
        setCredits(data[0].credits);
        // console.log(data[0].credits);
      }
    } catch (error) {
      message.error(error.message + "");
    } finally {
      setIsLoading(false);
    }
  }

  async function signout() {
    const { error } = await supabase.auth.signOut();
    setToken("");
    await navigate("/sign-in");
  }

  useEffect(() => {
    fetchCredits(email);
  }, []);

  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu profileiconul">
        <li className="menu-item-has-children itemsnav">
          <a target="_blank" className="servicesddm">
            Services
            <FaAngleDown />{" "}
          </a>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/design-thumbnail"}>
                Design Thumbnail{" "}
                <img
                  className="crownicon"
                  src={process.env.PUBLIC_URL + "/images/crown.png"}
                ></img>
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/upscale"}>
                Upscale Image{" "}
                <img
                  className="crownicon"
                  src={process.env.PUBLIC_URL + "/images/crown.png"}
                ></img>
              </Link>
            </li>

            <li>
              <Link to={process.env.PUBLIC_URL + "/bg-remove"}>
                Remove Background{" "}
                <img
                  className="crownicon"
                  src={process.env.PUBLIC_URL + "/images/crown.png"}
                ></img>
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/ai-analyze"}>
                Analyze Thumbnail
              </Link>
            </li>
          </ul>
        </li>
        <li className="itemsnav">
          <Link to={"/about-us"}>About Us</Link>
        </li>
        <li className="itemsnav">
          <Link to={"/contact"}>Contact</Link>
        </li>
        <li className="itemsnav">
          <Link to={"/pricing"}>Pricing</Link>
        </li>
        <li className="crediticon">
          <Popover
            placement="bottomLeft"
            title={text}
            content={content}
            arrow={mergedArrow}
          >
            <Link
              to={process.env.PUBLIC_URL + "/profile"}
              className="credit-number change-cursor"
            >
              {credits != null ? (
                <>
                  {/* <b className="credits-hover">Credits: </b>  */}
                  <b>{credits}</b>
                </>
              ) : (
                <>
                  <img className="creditloading" src="images/oval.svg" />
                </>
              )}{" "}
              <img
                className="dollaricon"
                src={process.env.PUBLIC_URL + "/images/token.png"}
              ></img>
            </Link>{" "}
          </Popover>
        </li>

        <li className="menu-item-has-children itemsnav">
          <li className="profileicon">
            <Link
              className="change-cursor"
              to={process.env.PUBLIC_URL + "/profile"}
            >
              <Avatar
                className="avatar"
                sx={{
                  bgcolor: "#00adb4",
                  width: 35,
                  height: 35,
                  boxShadow:
                    "0 0 30px 0 rgba(69, 196, 234, 0.15), 0 6px 12px 0 rgba(23, 210, 224, 0.15)",
                }}
              >
                {user[0].toUpperCase()}
              </Avatar>
            </Link>
          </li>
          <ul className="axil-submenu-loggedin axil-submenu-signin-loggedin">
            <li className="loggedin-text centeralign">
              {user.length > 13 ? user.slice(0, 13) + "..." : user}
            </li>

            <li>
              <span>
                <Link onClick={handleLogout} to={""}>
                  <span>
                    <LogoutRoundedIcon />
                  </span>{" "}
                  <span>Log Out</span>
                </Link>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default LoggedInNav;
